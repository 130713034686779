import { Exception } from "shared";
import { ApiService } from "../ApiService";
import { ConfigService } from "../ConfigService";
import { FirebaseService } from "../FirebaseService";
import { LocalStorageService } from "../LocalStorageService";
import * as Facebook from "./facebook";
import * as Google from "./google";

// ------------------
// Internal Constants
// ------------------

const googleProviderId = "google.com";
const facebookProviderId = "facebook.com";
const identityCreatedAtStorageKey = "identityCreatedAt";

const credentialInUseErrorCode = "auth/credential-already-in-use";
const providerAlreadyLinkedErrorCode = "auth/provider-already-linked";
const emailAlreadyInUseErrorCode = "auth/email-already-in-use";
const existsWithDifferentCredentialsErrorCode =
  "auth/account-exists-with-different-credential";

const onloginChangedCallbacks: Array<() => void> = [];

// ------------------
// Internal Variables
// ------------------

let currentUser: any = null;
let initilized: Promise<void> | null = null;

// ----------------
// Exported Classes
// ----------------

export class IdentityService {
  public static emailInUseErrorCode = "identity/email-in-use";
  public static init(): Promise<void> {
    if (initilized) {
      return initilized;
    }

    let userLoaded: boolean = false;

    initilized = new Promise(async mainResolve => {
      const userLoadedPromise = new Promise(resolve => {
        FirebaseService.auth().onAuthStateChanged(user => {
          currentUser = user;
          if (!userLoaded) {
            userLoaded = true;
            resolve();
          }
          IdentityService.userChanged();
        });
      });

      if (!LocalStorageService.get(identityCreatedAtStorageKey)) {
        await FirebaseService.auth().signInAnonymously();
        LocalStorageService.set(
          identityCreatedAtStorageKey,
          new Date().toISOString()
        );
      }

      const promises = [userLoadedPromise];
      if (!ConfigService.inTestMode) {
        promises.push(Google.init(), Facebook.init());
      }

      await Promise.all(promises);

      mainResolve();
    });

    return initilized;
  }

  public static async getCurrentUser(): Promise<any> {
    if (currentUser) {
      return currentUser;
    }

    await IdentityService.init();
    return currentUser;
  }

  public static async isUserAnonymous(): Promise<boolean> {
    const user = await IdentityService.getCurrentUser();
    return user.isAnonymous;
  }

  public static isFacebookUser(): Promise<boolean> {
    return IdentityService.isUserWithProvider(facebookProviderId);
  }

  public static isGoogleUser(): Promise<boolean> {
    return IdentityService.isUserWithProvider(googleProviderId);
  }

  public static async loginWithFacebook(): Promise<any> {
    const accessToken = await Facebook.login();

    if (accessToken) {
      const credential = FirebaseService.auth.FacebookAuthProvider.credential(
        accessToken
      );

      await IdentityService.linkCredential(credential);
    }
  }

  public static async loginWithGoogle(): Promise<void> {
    const idToken = await Google.login();

    if (idToken) {
      const credential = FirebaseService.auth.GoogleAuthProvider.credential(
        idToken
      );
      await IdentityService.linkCredential(credential);
    }
  }

  public static async logout(): Promise<void> {
    LocalStorageService.set(identityCreatedAtStorageKey, undefined);
    await Promise.all([
      Facebook.logout(),
      Google.logout(),
      FirebaseService.auth().signOut()
    ]);

    initilized = null;
    await IdentityService.init();
  }

  public static async onloginChanged(callback: () => void) {
    onloginChangedCallbacks.push(callback);
  }

  public static async isUserWithProvider(providerId: string) {
    const user = await IdentityService.getCurrentUser();

    if (!user.isAnonymous) {
      const providerData = user.providerData;
      if (providerData) {
        for (const provider of providerData) {
          if (provider.providerId === providerId) {
            return true;
          }
        }
      }
    }

    return false;
  }

  private static async linkCredential(credential: any) {
    const user = await IdentityService.getCurrentUser();

    try {
      await user.linkWithCredential(credential);
    } catch (error) {
      if (error) {
        switch (error.code) {
          case emailAlreadyInUseErrorCode:
          case existsWithDifferentCredentialsErrorCode:
            throw new Exception(
              IdentityService.emailInUseErrorCode,
              "The given email is already linked to an account."
            );
          case credentialInUseErrorCode:
            if (user.isAnonymous) {
              await ApiService.user(true);
            }
            await FirebaseService.auth().signInWithCredential(credential);
            return;
          case providerAlreadyLinkedErrorCode:
            return;
          default:
          // do nothing
        }
      }
    }

    IdentityService.userChanged();
  }

  private static userChanged() {
    if (currentUser) {
      for (const callback of onloginChangedCallbacks) {
        callback();
      }
    }
  }
}
