// ------------------
// Internal Constants
// ------------------

const mainKey = "puzzle-slider";

// ------------------
// Internal Variables
// ------------------

let allsettings: { [index: string]: any };

// ------------------
// Internal Functions
// ------------------

function getSettings() {
  if (!allsettings) {
    const settingsString = localStorage.getItem(mainKey);
    allsettings = settingsString ? JSON.parse(settingsString) : {};
  }
  return allsettings;
}

function setSettings() {
  localStorage.setItem(mainKey, JSON.stringify(getSettings()));
}

function checkForReset() {
  if (window.location.href.indexOf("reset") !== -1) {
    localStorage.removeItem(mainKey);
  }
}

// -----------
// Module Init
// -----------

checkForReset();

// ----------------
// Exported Classes
// ----------------

export class LocalStorageService {

  public static get(key: string): any {
    const settings = getSettings();
    return settings[key];
  }

  public static set(key: string, value: any): void {
    const settings = getSettings();
    const oldValue = settings[key];
    if (oldValue !== value) {
      settings[key] = value;
      const handlers = LocalStorageService.priOnSettingChangedHandlers[key];
      if (handlers) {
        for (const handler of handlers) {
          handler(value, oldValue);
        }
      }
    }

    setSettings();
  }

  public static onSettingChanged(
    key: string,
    handler: (value: any, previous: any) => void
  ) {
    const handlers = LocalStorageService.priOnSettingChangedHandlers[key];
    if (!handlers) {
      LocalStorageService.priOnSettingChangedHandlers[key] = [handler];
    } else {
      handlers.push(handler);
    }
  }
  private static priOnSettingChangedHandlers: {
    [index: string]: Array<(value: any, previous: any) => void> | undefined;
  } = {};
}
