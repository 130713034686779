import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { GameState, Puzzle } from "shared";
import { useResize } from "../../hooks";
import { ClientService } from "../../services";
import { Fade } from "../Fade";
import { PuzzleBoard } from "../PuzzleBoard";
import { TimeLabel } from "../TimeLabel";
import "./styles.css";

// --------------
// Internal Enums
// --------------

enum ButtonState {
  start = "start",
  resume = "resume",
  fadeResume = "fadeResume"
}

// -------------------
// Exported Interfaces
// -------------------

export interface ILevelScreenProps {
  show?: boolean;
  fadeIn?: boolean;
  fadeOut?: boolean;
  onFadeChange?: (fadeIn: boolean) => void;
}

// -------------------
// Exported Components
// -------------------

export const LevelScreen: React.FC<ILevelScreenProps> = ({
  show,
  fadeIn,
  fadeOut,
  onFadeChange
}) => {
  ClientService.default.nextGame();
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const imageSize = useResize(imageContainerRef);
  const [game, setGame] = useState(ClientService.default.game);
  const [image, setImage] = useState(ClientService.default.image);
  const [timeLabel, setTimeLabel] = useState(ClientService.default.timeLabel);
  const [showLevel, setShowLevel] = useState(show);
  const [firstLoad, setFirstLoad] = useState(true);
  const [buttonState, setButtonState] = useState(
    game && game.state === GameState.inProgress
      ? ButtonState.resume
      : ButtonState.start
  );

  const container = useRef<HTMLDivElement>(null);
  const containerSize = useResize(container);

  const landscape =
    containerSize.width > containerSize.height && containerSize.height < 500;

  useEffect(() => {
    ClientService.default.onGameChanged(setGame);
    ClientService.default.onImageChanged(setImage);
    ClientService.default.onTimeLabelChanged(setTimeLabel);
    ClientService.default.onTimeExpired(onTimeExpired);

    return () => {
      ClientService.default.offGameChanged(setGame);
      ClientService.default.offImageChanged(setImage);
      ClientService.default.offTimeLabelChanged(setTimeLabel);
      ClientService.default.offTimeExpired(onTimeExpired);
    };
  }, []);

  useLayoutEffect(() => {
    if (game && game.state !== GameState.inProgress) {
      if (buttonState === ButtonState.resume) {
        if (firstLoad) {
          setButtonState(ButtonState.start);
        } else {
          setButtonState(ButtonState.fadeResume);
        }
      }
    }
    if (firstLoad) {
      setFirstLoad(false);
    }
  }, [game, buttonState, firstLoad]);

  function onTimeExpired() {
    ClientService.default.nextGame();
    setButtonState(ButtonState.fadeResume);
  }

  function onStartClick() {
    ClientService.default.offTimeLabelChanged(setTimeLabel);
    ClientService.default.startGame();
    setShowLevel(false);
  }

  function onResumeClick() {
    ClientService.default.offTimeLabelChanged(setTimeLabel);
    setShowLevel(false);
  }

  function onResumeButtonFadeOut() {
    setButtonState(ButtonState.start);
  }

  async function onRestartClick() {
    await ClientService.default.quitGame();
    ClientService.default.nextGame();
  }

  return (
    <Fade
      fadeIn={fadeIn === undefined ? false : fadeIn}
      fadeOut={fadeOut === undefined ? false : fadeOut}
      show={showLevel}
      onFadeChange={onFadeChange}
    >
      <div className="level" ref={container}>
        <div className="level-title">{`Level ${
          game ? game.level + 1 : "-"
        }`}</div>

        <div className={`level-panel${landscape ? "-landscape" : ""}`}>
          <div
            className={`level-image${landscape ? "-landscape" : ""}`}
            ref={imageContainerRef}
          >
            <PuzzleBoard
              puzzle={game ? Puzzle.withSize(game.size) : undefined}
              image={image}
              square={{ size: imageSize.width, left: 0, top: 0 }}
              borderSize={0}
              displayOnly={true}
            />
          </div>
          <div className={`level-details${landscape ? "-landscape" : ""}`}>
            <div className="level-details-line">
              <div className="level-details-line-left">Attempt</div>
              <div className="level-details-line-spacer" />
              <div className="level-details-line-right">
                {game ? game.attempt + 1 : "-"}
              </div>
            </div>
            <div className="level-details-line">
              <div className="level-details-line-left">Size</div>
              <div className="level-details-line-spacer" />
              <div className="level-details-line-right">
                {game ? `${game.size}×${game.size}` : "-"}
              </div>
            </div>
            <div className="level-details-line">
              <div className="level-details-line-left">Time</div>
              <div className="level-details-line-spacer" />
              <div className="level-details-line-right">
                <TimeLabel label={timeLabel} />
              </div>
            </div>

            <div
              className={`level-button-container${
                landscape ? "-landscape" : ""
              }`}
            >
              <Fade fadeIn show={buttonState === ButtonState.start}>
                <div className="level-button" onClick={onStartClick}>
                  Start
                </div>
              </Fade>

              <Fade
                fadeOut={buttonState !== ButtonState.start}
                show={buttonState === ButtonState.resume}
                onFadeChange={onResumeButtonFadeOut}
              >
                <div className="level-two-button-container">
                  <div className="level-button" onClick={onRestartClick}>
                    Restart
                  </div>
                  <div className="level-button" onClick={onResumeClick}>
                    Resume
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
};
