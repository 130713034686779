import React, { useLayoutEffect, useRef, useState } from "react";
import { useResize } from "../../hooks";
import { ClientService } from "../../services";
import { Exit } from "../Exit";
import { Fade } from "../Fade";
import { LogoSmall } from "../LogoSmall";
import { PuzzleBoard } from "../PuzzleBoard";
import { Spinner } from "../Spinner";
import { Timer } from "../Timer";
import "./styles.css";

// ------------------
// Internal Constants
// ------------------

const puzzleDoneDelay = 3 * 1000;
const timeExpiredMessage = "Try Again";
const solvedMessage = "Solved";

// --------------
// Internal Enums
// --------------

enum Screen {
  spinner = "spinner",
  fadeSpinner = "fadeSpinner",
  board = "board"
}

// -------------------
// Exported Interfaces
// -------------------

export interface IGameScreenProps {
  show?: boolean;
  fadeIn?: boolean;
  fadeOut?: boolean;
  onFadeChange?: (fadeIn: boolean) => void;
}

// -------------------
// Exported Components
// -------------------

export const GameScreen: React.FC<IGameScreenProps> = ({
  show,
  fadeIn,
  fadeOut,
  onFadeChange
}) => {
  const [game, setGame] = useState(ClientService.default.game);
  const [puzzle, setPuzzle] = useState(ClientService.default.puzzle);
  const [image, setImage] = useState(ClientService.default.image);
  const [screen, setScreen] = useState(
    puzzle && image ? Screen.board : Screen.spinner
  );
  const [showGame, setShowGame] = useState(show);
  const [puzzleLabel, setPuzzleLabel] = useState("");
  const container = useRef<HTMLDivElement>(null);
  const containerSize = useResize(container);

  const landscape =
    containerSize.width > containerSize.height && containerSize.height < 500;

  const vertical = containerSize.height - 140 > containerSize.width;
  const size = landscape
    ? containerSize.height
    : vertical
    ? containerSize.width
    : containerSize.height - 140;

  useLayoutEffect(() => {
    ClientService.default.onGameChanged(setGame);
    ClientService.default.onPuzzleChanged(setPuzzle);
    ClientService.default.onImageChanged(setImage);
    ClientService.default.onTimeExpired(onTimeExpired);

    if (game !== ClientService.default.game) {
      setGame(ClientService.default.game);
    }
    if (puzzle !== ClientService.default.puzzle) {
      setPuzzle(ClientService.default.puzzle);
    }
    if (image !== ClientService.default.image) {
      setImage(ClientService.default.image);
    }
    return () => {
      ClientService.default.offGameChanged(setGame);
      ClientService.default.offPuzzleChanged(setPuzzle);
      ClientService.default.offImageChanged(setImage);
      ClientService.default.offTimeExpired(onTimeExpired);
    };
    // eslint-disable-next-line
  }, []);

  function onSolved() {
    ClientService.default.sendMoves();
    setPuzzleLabel(solvedMessage);
  }

  function onMove(moves: number[]) {
    ClientService.default.setMoves(moves);
  }

  async function exit() {
    if (puzzle && puzzle.isSolved) {
      while (!ClientService.default.nextGameAndImageReady) {
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }
    setShowGame(false);
  }

  function onSolvedAnimationDone() {
    setTimeout(exit, puzzleDoneDelay);
  }

  function onExitClick() {
    exit();
  }

  function onTimeExpired() {
    ClientService.default.offGameChanged(setGame);
    ClientService.default.offPuzzleChanged(setPuzzle);
    ClientService.default.offImageChanged(setImage);
    setPuzzleLabel(timeExpiredMessage);
    setTimeout(() => {
      setShowGame(false);
    }, puzzleDoneDelay);
  }

  function nextScreen(spinnerFadeIn?: boolean) {
    if (!spinnerFadeIn) {
      setScreen(screen === Screen.spinner ? Screen.fadeSpinner : Screen.board);
    }
  }

  if (puzzle && image && screen === Screen.spinner) {
    nextScreen();
  }
  const content =
    screen === Screen.board ? (
      landscape ? (
        <div className="game-screen-container-landscape">
          <div className="game-screen-toolbar-landscape">
            <div className="game-screen-timer-landscape">
              <Timer pause={puzzleLabel !== ""} verticalLayout />
            </div>

            <div className="game-screen-exit-landscape">
              <Exit onClick={onExitClick} />
            </div>
          </div>

          <div className="game-screen-board-landscape">
            <PuzzleBoard
              puzzle={puzzle}
              image={image}
              borderSize={20}
              onSolved={onSolved}
              onMove={onMove}
              square={{ size, left: 0, top: 0 }}
              onSolvedAnimationDone={onSolvedAnimationDone}
              freeze={puzzleLabel !== ""}
              freezeOverlayLabel={puzzleLabel}
            />
          </div>
        </div>
      ) : (
        <div className="game-screen-container">
          <div className="game-screen-toolbar">
            <div className="game-screen-logo">
              <LogoSmall />
            </div>
            <div className="game-screen-timer">
              <Timer pause={puzzleLabel !== ""} />
            </div>

            <div className="game-screen-exit">
              <Exit onClick={onExitClick} />
            </div>
          </div>

          <div className="game-screen-board">
            <PuzzleBoard
              puzzle={puzzle}
              image={image}
              borderSize={20}
              onSolved={onSolved}
              onMove={onMove}
              square={{ size, left: 0, top: 0 }}
              onSolvedAnimationDone={onSolvedAnimationDone}
              freeze={puzzleLabel !== ""}
              freezeOverlayLabel={puzzleLabel}
            />
          </div>
        </div>
      )
    ) : (
      <Spinner
        fadeOut
        show={screen === Screen.spinner}
        onFadeChange={nextScreen}
        label="Loading..."
      />
    );

  return (
    <Fade
      fadeIn={fadeIn === undefined ? false : fadeIn}
      fadeOut={fadeOut === undefined ? false : fadeOut}
      show={showGame}
      onFadeChange={onFadeChange}
    >
      <div className="game-screen" ref={container}>
        {content}
      </div>
    </Fade>
  );
};
