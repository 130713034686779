// --------------
// Exported Enums
// --------------
export var GameState;
(function (GameState) {
    GameState["generated"] = "generated";
    GameState["inProgress"] = "inProgress";
    GameState["solved"] = "solved";
    GameState["quit"] = "quit";
    GameState["expired"] = "expired";
})(GameState || (GameState = {}));
