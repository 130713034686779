import { Exception, IGame, IGameUpdate, Random } from "shared";
import { FirebaseService } from "./FirebaseService";
import { IdentityService } from "./IdentityService";

// ------------------
// Internal Constants
// ------------------

const maxRetry = 10;
const functions = FirebaseService.functions();
const outstandingPromises: {
  [index: string]: Promise<any> | undefined;
} = {};

// ------------------
// Internal Functions
// ------------------

async function delayforRetry(retry: number): Promise<void> {
  await new Promise(resolve =>
    setTimeout(resolve, Random.withMax(1000) + retry * retry * 100)
  );
}

function callFunction(
  key: string,
  url: string,
  body: any,
  retry: number = 0
): Promise<any> {
  let promise = outstandingPromises[key];
  if (!promise) {
    promise = new Promise(async (resolve, reject) => {
      await IdentityService.init();

      let response;
      try {
        response = await functions.httpsCallable(url)(body || undefined);
      } catch (error) {
        if (error.code === "internal" && retry <= maxRetry) {
          await delayforRetry(retry);
          outstandingPromises[key] = undefined;
          resolve(await callFunction(key, url, body, retry + 1));
        }
        reject(new Exception(undefined, error.message));
      }

      if (response && response.data) {
        resolve(response.data);
      } else {
        reject(
          new Exception(ApiService.noResponseDataErrorCode, "No response data")
        );
      }

      outstandingPromises[key] = undefined;
    });
    outstandingPromises[key] = promise;
  }

  return promise;
}

// ----------------
// Exported Classes
// ----------------

export class ApiService {
  public static noResponseDataErrorCode = "ApiService/no-response-data";

  public static async game(update?: IGameUpdate): Promise<IGame> {
    const key = update
      ? `game:${update.start}:${update.quit}:${update.moves}`
      : "game:<get-only>";
    return callFunction(key, "v1/game", update);
  }

  public static async user(abandoned: boolean): Promise<void> {
    const key = `user:${abandoned}`;
    return callFunction(key, "v1/user", { abandoned });
  }
}
