import { EventEmitter } from "events";
import {
  Color,
  Exception,
  GameState,
  IGame,
  IGameUpdate,
  Puzzle
} from "shared";
import { ApiService } from "./ApiService";
import { ConfigService } from "./ConfigService";
import { LocalStorageService } from "./LocalStorageService";

// ------------------
// Internal Constants
// ------------------

const backgroundColorsKey = "background-colors";

// --------------
// Exported Class
// --------------

export class ClientService extends EventEmitter {
  static get default(): ClientService {
    return ClientService.priDefault;
  }

  get game(): IGame | undefined {
    return this.priGame;
  }

  get nextGameAndImageReady(): boolean {
    return this.priNextGame !== undefined && this.priNextImage !== undefined;
  }

  get image(): HTMLImageElement | undefined {
    return this.priImage;
  }

  get puzzle(): Puzzle | undefined {
    return this.priPuzzle;
  }

  get timeLabel(): string | undefined {
    if (!this.game) {
      return undefined;
    }

    const remaining = this.timePercentage;
    if (remaining) {
      const totalSeconds = Math.floor(
        (remaining * this.game.timeAllowed) / 1000
      );
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;

      const minutesLabel = (minutes > 0 ? minutes : 0).toString();
      const secondsLabel = (seconds > 9 ? seconds : "0" + seconds).toString();
      return `${minutesLabel}:${secondsLabel}`;
    }
    return undefined;
  }

  get timePercentage(): number | undefined {
    if (!this.game) {
      return undefined;
    }

    if (this.game.state === GameState.generated) {
      return 1;
    }

    const now = Date.now();
    let startTime =
      this.priAdjustedStartTime !== undefined
        ? this.priAdjustedStartTime
        : new Date(this.game.startedAt as string).valueOf();
    if (startTime + ConfigService.startTimeOffset > now) {
      if (this.priAdjustedStartTime === undefined) {
        this.priAdjustedStartTime = startTime = now;
      }
    } else {
      this.priAdjustedStartTime = undefined;
    }

    const endTime = new Date(this.game.expiresAt as string).valueOf();

    const duration = endTime - startTime;
    const elapsed = now - startTime;
    let remaining = duration - elapsed;
    if (remaining <= 0) {
      remaining = 0;
    }
    return remaining === 0 ? 0 : remaining / duration;
  }

  get backgroundColors(): Color[] | undefined {
    return this.priGame
      ? this.priGame.image.backgroundColors
      : (LocalStorageService.get(backgroundColorsKey) as Color[]);
  }

  public static gameNotAvailableErrorCode = "ClientService/game-not-available";
  public static gameChangedEvent = "gameChanged";
  public static puzzleChangedEvent = "puzzleChanged";
  public static imageChangedEvent = "imageChanged";
  public static timeLabelChangedEvent = "timeLabelChanged";
  public static timePercentageChangedEvent = "timePrecentageChanged";
  public static timeExpiredEvent = "timeExpiredChanged";
  public static backgroundColorsChangedEvent = "backgroundColors";

  private static priDefault: ClientService = new ClientService();
  private priGame: IGame | undefined;
  private priNextGame: IGame | undefined;
  private priImage: HTMLImageElement | undefined;
  private priNextImage: HTMLImageElement | undefined;
  private priPuzzle: Puzzle | undefined;
  private priMoves: number[] | undefined;
  private priSendMovesTimeout: NodeJS.Timeout | undefined;
  private priLastTimeLabel: string | undefined;
  private priLastTimePercentage: number | undefined;
  private priNextTimeout: NodeJS.Timeout | undefined;
  private priAdjustedStartTime: number | undefined;
  private priQuiting: number = 0;

  public async fetchGame(): Promise<void> {
    return this.getGame();
  }

  public async startGame(): Promise<void> {
    await this.getGame(true);
  }

  public async quitGame(): Promise<void> {
    this.priQuiting++;
    const result = this.getGame(false, true);
    this.priQuiting--;
    return result;
  }

  public async sendMoves(): Promise<void> {
    if (this.priSendMovesTimeout) {
      clearTimeout(this.priSendMovesTimeout);
      return this.getGame(false, false, true);
    }
  }

  public setMoves(moves: number[]): void {
    if (this.priGame && this.priGame.state === GameState.inProgress) {
      this.priMoves = moves;
      if (!this.priSendMovesTimeout) {
        this.priSendMovesTimeout = setTimeout(() => {
          this.priSendMovesTimeout = undefined;
          this.getGame(false, false, true);
        }, ConfigService.sendMovesThrottle);
      }
    }
  }

  public async nextGame(): Promise<void> {
    if (
      !this.priNextGame &&
      this.priGame &&
      this.priGame.state === GameState.inProgress &&
      new Date(this.priGame.expiresAt as string).valueOf() <
        Date.now() + ConfigService.restartIfExpiresOffset
    ) {
      await this.quitGame();
    }

    if (this.priQuiting > 0) {
      await this.quitGame();
    }

    if (this.priNextGame) {
      this.priGame = this.priNextGame;
      this.priNextGame = undefined;
      this.priPuzzle = undefined;
      this.emitOnGameChanged();
      if (this.priNextImage) {
        this.priImage = this.priNextImage;
        this.emitOnImageChanged();
        this.priNextImage = undefined;
      }
      this.updatePuzzle(this.priGame);
      this.updateBackgroundColors(this.priGame);
    }
  }

  public onGameChanged(handler: (game: IGame) => void): void {
    this.on(ClientService.gameChangedEvent, handler);
  }

  public onImageChanged(handler: (image: HTMLImageElement) => void): void {
    this.on(ClientService.imageChangedEvent, handler);
  }

  public onTimeLabelChanged(handler: (timeLabel: string) => void): void {
    this.on(ClientService.timeLabelChangedEvent, handler);
  }

  public onTimeExpired(handler: () => void): void {
    this.on(ClientService.timeExpiredEvent, handler);
  }

  public onTimePercentageChanged(
    handler: (timePercentage: number) => void
  ): void {
    this.on(ClientService.timePercentageChangedEvent, handler);
  }

  public onPuzzleChanged(handler: (puzzle: Puzzle) => void): void {
    this.on(ClientService.puzzleChangedEvent, handler);
  }

  public onBackgroundColorsChanged(handler: (colors: Color[]) => void): void {
    this.on(ClientService.backgroundColorsChangedEvent, handler);
  }

  public offGameChanged(handler: (game: IGame) => void): void {
    this.off(ClientService.gameChangedEvent, handler);
  }

  public offImageChanged(handler: (image: HTMLImageElement) => void): void {
    this.off(ClientService.imageChangedEvent, handler);
  }

  public offTimeLabelChanged(handler: (timeLabel: string) => void): void {
    this.off(ClientService.timeLabelChangedEvent, handler);
  }

  public offTimePercentageChanged(
    handler: (timePercentage: number) => void
  ): void {
    this.off(ClientService.timePercentageChangedEvent, handler);
  }

  public offTimeExpired(handler: () => void): void {
    this.off(ClientService.timeExpiredEvent, handler);
  }

  public offPuzzleChanged(handler: (puzzle: Puzzle) => void): void {
    this.off(ClientService.puzzleChangedEvent, handler);
  }

  public offBackgroundColorsChanged(handler: (colors: Color[]) => void): void {
    this.off(ClientService.backgroundColorsChangedEvent, handler);
  }

  private async getGame(
    start: boolean = false,
    quit: boolean = false,
    moves: boolean = false
  ) {
    let gameUpdate: IGameUpdate | undefined;

    if (start || quit || moves) {
      if (!this.priGame) {
        await this.getGame();

        if (!this.priGame) {
          throw new Exception(
            ClientService.gameNotAvailableErrorCode,
            "Unable to get the current game from the API"
          );
        }
      }

      gameUpdate = {
        attempt: this.priGame.attempt,
        level: this.priGame.level,
        moves: this.priMoves || [],
        quit: quit || false,
        start: start || false
      };

      this.priMoves = [];
    }

    let gameUpdated: IGame | undefined;
    try {
      gameUpdated = await ApiService.game(gameUpdate);
    } catch (exception) {
      if (exception.code) {
        if (
          exception.code === "not-current-level" ||
          exception.code === "not-current-attempt"
        ) {
          this.priGame = undefined;
          this.priNextGame = undefined;
          this.priImage = undefined;
          this.priNextImage = undefined;
          this.getGame();
          return;
        }
      }
    }

    if (gameUpdated) {
      this.updateGame(gameUpdated);
    }
  }

  private updateGame(game: IGame) {
    const currentGame = this.priGame;
    if (!currentGame) {
      this.priGame = game;
      this.emitOnGameChanged();
      this.updateImageElement(game.image.file);
      this.updateBackgroundColors(game);
      this.updatePuzzle(game);
    } else if (
      currentGame.level !== game.level ||
      currentGame.attempt !== game.attempt
    ) {
      this.priNextGame = game;
      this.updateImageElement(game.image.file, true);
    } else if (currentGame.state !== game.state) {
      this.priGame = game;
      this.emitOnGameChanged();
      this.updatePuzzle(game);
    }

    if (this.priGame && this.priGame.state === GameState.inProgress) {
      this.startTimer();
    } else {
      this.priAdjustedStartTime = undefined;
      this.priLastTimePercentage = undefined;
      this.priLastTimeLabel = undefined;
      this.clearTimer();
    }
  }

  private updateImageElement(imageFile: string, isNext: boolean = false): void {
    const image = new Image();
    image.onload = () => {
      if (isNext && this.priNextGame) {
        this.priNextImage = image;
      } else {
        this.priImage = image;
        this.emitOnImageChanged();
      }
    };
    if (window.innerHeight < 500 || window.innerWidth < 500) {
      imageFile = imageFile.replace("600", "300");
    }
    image.src = `${ConfigService.puzzleUrl}/${imageFile}`;
  }

  private updatePuzzle(game: IGame) {
    if (game.puzzleStringified) {
      this.priPuzzle = Puzzle.parse(game.puzzleStringified);
      this.emitOnPuzzleChanged();
    }
  }

  private startTimer() {
    this.clearTimer();
    this.priNextTimeout = setTimeout(async () => {
      const percentage = this.timePercentage;
      if (percentage !== this.priLastTimePercentage) {
        this.priLastTimePercentage = percentage;
        this.emitOnTimePercentageChanged(percentage);
        if (percentage !== undefined && percentage < 0.0001) {
          if (this.game && this.game.state === GameState.inProgress) {
            this.game.state = GameState.expired;
          }
          this.emitOnTimeExpired();
          await this.quitGame();
          this.nextGame();
        }
      }
      const label = this.timeLabel;
      if (label !== this.priLastTimeLabel) {
        this.priLastTimeLabel = label;
        this.emitOnTimeLabelChanged(label);
      }

      this.startTimer();
    }, 100);
  }

  private clearTimer() {
    if (this.priNextTimeout) {
      clearTimeout(this.priNextTimeout);
      this.priNextTimeout = undefined;
    }
  }

  private updateBackgroundColors(game: IGame) {
    LocalStorageService.set(backgroundColorsKey, game.image.backgroundColors);
    this.emitOnBackgroundColorsChanged();
  }

  private emitOnGameChanged() {
    this.emit(ClientService.gameChangedEvent, this.priGame);
  }

  private emitOnPuzzleChanged() {
    this.emit(ClientService.puzzleChangedEvent, this.priPuzzle);
  }

  private emitOnImageChanged() {
    this.emit(ClientService.imageChangedEvent, this.priImage);
  }

  private emitOnTimePercentageChanged(timePercentage: number | undefined) {
    this.emit(ClientService.timePercentageChangedEvent, timePercentage);
  }

  private emitOnTimeLabelChanged(timeLabel: string | undefined) {
    this.emit(ClientService.timeLabelChangedEvent, timeLabel);
  }

  private emitOnTimeExpired() {
    this.emit(ClientService.timeExpiredEvent);
  }

  private emitOnBackgroundColorsChanged() {
    this.emit(
      ClientService.backgroundColorsChangedEvent,
      this.priGame ? this.priGame.image.backgroundColors : undefined
    );
  }
}
