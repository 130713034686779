import React, { useEffect, useLayoutEffect, useState } from "react";
import { Fade } from "../Fade";
import "./styles.css";

// ------------------
// Internal Constants
// ------------------

const delayDuration = 500;
const minShowDuration = delayDuration + 2 * 1000;
const defaultColor = "white";
const squareSize = 10;
const gapSize = 4;
const puzzleSize = 3;
const strokeWidth = 1.5;
const viewBoxSize = squareSize * puzzleSize + gapSize * (puzzleSize + 1);
const viewBox = `0 0 ${viewBoxSize} ${viewBoxSize}`;
const stageFrames = 25;
const stages = 8;
const maxFrames = stageFrames * stages;
const animationSquares = [
  [2, 2],
  [2, 1],
  [1, 1],
  [1, 0],
  [0, 0],
  [0, 1],
  [1, 1],
  [1, 2]
];

// -------------------
// Exported Interfaces
// -------------------

export interface ISpinnerProps {
  show?: boolean;
  fadeIn?: boolean;
  fadeOut?: boolean;
  onFadeChange?: (fadeIn: boolean) => void;
  color?: string;
  animationRate?: number;
  label?: string;
}

// -------------------
// Exported Components
// -------------------

export const Spinner: React.FC<ISpinnerProps> = ({
  show,
  fadeIn,
  fadeOut,
  onFadeChange,
  color,
  label
}) => {
  color = color || defaultColor;

  const [frame, setFrame] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [startTime] = useState(Date.now());

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const now = Date.now();
    const duration = now - startTime;

    if (!show) {
      if (showSpinner) {
        if (duration < minShowDuration) {
          timeout = setTimeout(
            () => setShowSpinner(false),
            minShowDuration - duration
          );
        } else {
          setShowSpinner(false);
        }
      } else if (duration < minShowDuration) {
        if (onFadeChange) {
          onFadeChange(false);
        }
      }
    } else {
      timeout = setTimeout(
        () => setShowSpinner(true),
        delayDuration - duration
      );
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
    // eslint-disable-next-line
  }, [show, showSpinner]);

  useLayoutEffect(() => {
    const animationFrameRequst: number = window.requestAnimationFrame(() => {
      setFrame((frame + 1) % maxFrames);
    });

    return () => {
      window.cancelAnimationFrame(animationFrameRequst);
    };
  }, [frame]);

  const stage = Math.floor(frame / stageFrames);
  const frameInStage = frame % stageFrames;
  const toSquare = animationSquares[stage];
  const fromSquare = animationSquares[(stage + 1) % animationSquares.length];
  const offset = ((squareSize + gapSize) / stageFrames) * frameInStage;

  const rects = [];
  for (let row = 0; row < puzzleSize; row++) {
    for (let col = 0; col < puzzleSize; col++) {
      if (row !== toSquare[0] || col !== toSquare[1]) {
        let x = (col + 1) * gapSize + col * squareSize;
        let y = (row + 1) * gapSize + row * squareSize;
        if (row === fromSquare[0] && col === fromSquare[1]) {
          if (toSquare[0] === fromSquare[0]) {
            const direction = toSquare[1] > fromSquare[1] ? 1 : -1;
            x = x + offset * direction;
          } else {
            const direction = toSquare[0] > fromSquare[0] ? 1 : -1;
            y = y + offset * direction;
          }
        }
        rects.push(
          <rect
            key={`${row}:${col}`}
            x={x}
            y={y}
            width={squareSize}
            height={squareSize}
            fillOpacity={0}
            stroke={color}
            strokeWidth={strokeWidth}
          />
        );
      }
    }
  }

  return (
    <Fade
      fadeIn={fadeIn === undefined ? false : fadeIn}
      fadeOut={fadeOut === undefined ? false : fadeOut}
      show={showSpinner}
      onFadeChange={onFadeChange}
    >
      <div className="spinner" tabIndex={-1}>
        <div className="spinner-container">
          <svg
            width="100%"
            height="100%"
            viewBox={viewBox}
            fillRule="evenodd"
            clipRule="evenodd"
            strokeMiterlimit="2"
            pointerEvents="none"
          >
            {rects}
          </svg>
        </div>
        {label ? <div className="spinner-label">{label}</div> : null}
      </div>
    </Fade>
  );
};
