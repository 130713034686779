import { Exception } from "./Exception";
import { Random } from "./Random";
// ------------------
// Internal Constants
// ------------------
var segmentDelimiter = ":";
var squareDelimiter = ",";
// ----------------
// Exported Classes
// ----------------
var PuzzleState = /** @class */ (function () {
    function PuzzleState(rows, cols, squares) {
        this.priRows = rows;
        this.priCols = cols;
        this.priLength = rows * cols;
        this.priSquares = squares;
    }
    Object.defineProperty(PuzzleState.prototype, "rows", {
        get: function () {
            return this.priRows;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PuzzleState.prototype, "cols", {
        get: function () {
            return this.priCols;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PuzzleState.prototype, "length", {
        get: function () {
            return this.priLength;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PuzzleState.prototype, "isSolved", {
        get: function () {
            if (this.priIsSolved === undefined) {
                this.priIsSolved = this.getIsSolved();
            }
            return this.priIsSolved;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PuzzleState.prototype, "validMoves", {
        get: function () {
            if (this.priValidMoves === undefined) {
                this.priValidMoves = this.getValidMoves();
            }
            return this.priValidMoves.slice(0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PuzzleState.prototype, "squares", {
        get: function () {
            return this.priSquares.slice(0);
        },
        enumerable: true,
        configurable: true
    });
    PuzzleState.parse = function (stringified) {
        var segments = stringified.split(segmentDelimiter);
        if (segments.length !== 3) {
            throw new Exception(PuzzleState.parseFailedErrorCode, "Invalid number of segments");
        }
        var rows = parseInt(segments[0], 10);
        if (isNaN(rows) || rows < 1) {
            throw new Exception(PuzzleState.parseFailedErrorCode, "Invalid number of rows");
        }
        var cols = parseInt(segments[1], 10);
        if (isNaN(cols) || cols < 1) {
            throw new Exception(PuzzleState.parseFailedErrorCode, "Invalid number of cols");
        }
        var squareLabels = segments[2].split(squareDelimiter);
        var length = rows * cols;
        if (squareLabels.length !== length) {
            throw new Exception(PuzzleState.parseFailedErrorCode, "Invalid number of squares");
        }
        var squares = new Array(length);
        var seenSquares = new Array(length);
        for (var i = 0; i < length; i++) {
            var square = (squares[i] = parseInt(squareLabels[i], 10));
            if (isNaN(square) || square < -1 || square > length - 2) {
                throw new Exception(PuzzleState.parseFailedErrorCode, "Invalid square at index '" + i + "'");
            }
            if (seenSquares[square + 1]) {
                throw new Exception(PuzzleState.parseFailedErrorCode, "Square at index '" + i + "' not unique");
            }
            seenSquares[square + 1] = true;
        }
        return new PuzzleState(rows, cols, squares);
    };
    PuzzleState.fromMoves = function (puzzleState, moves) {
        var movesLength = moves.length;
        for (var i = 0; i < movesLength; i++) {
            if (moves[i] < 0 || moves[i] > puzzleState.length - 2) {
                throw new Exception(PuzzleState.invalidSquareErrorCode, "The move at index '" + i + "' is out of range");
            }
        }
        var newPuzzleState = new PuzzleState(puzzleState.priRows, puzzleState.priCols, puzzleState.priSquares.slice(0));
        for (var i = 0; i < movesLength; i++) {
            var move = moves[i];
            var validMoves = newPuzzleState.getValidMoves();
            if (!validMoves.includes(move)) {
                throw new Exception(PuzzleState.invalidSquareErrorCode, "The move at index '" + i + "' is not a valid move");
            }
            var squares = newPuzzleState.priSquares;
            var emptySquareIndex = squares.indexOf(PuzzleState.emptySquare);
            var squareToMoveIndex = squares.indexOf(move);
            squares[emptySquareIndex] = move;
            squares[squareToMoveIndex] = PuzzleState.emptySquare;
        }
        return newPuzzleState;
    };
    PuzzleState.withSize = function (size) {
        if (size < 1) {
            throw new Exception(PuzzleState.invalidSizeErrorCode, "The size argument is out of range");
        }
        var length = size * size;
        var squares = new Array(length);
        for (var i = 0; i < length - 1; i++) {
            squares[i] = i;
        }
        squares[length - 1] = PuzzleState.emptySquare;
        return new PuzzleState(size, size, squares);
    };
    PuzzleState.scrambled = function (puzzleState, moveCount, randomNumberGenerator) {
        if (moveCount < 1) {
            throw new Exception(PuzzleState.invalidMoveCountErrorCode, "The moveCount argument is out of range");
        }
        randomNumberGenerator = randomNumberGenerator || Random.withMax;
        var squares = puzzleState.priSquares;
        var firstAttempt = true;
        var emptySquareIndex = squares.indexOf(PuzzleState.emptySquare);
        var move;
        while (firstAttempt || puzzleState.getIsSolved()) {
            firstAttempt = false;
            for (var i = 0; i < moveCount; i++) {
                var validMoves = puzzleState.getValidMoves();
                if (move) {
                    validMoves.splice(validMoves.indexOf(move), 1);
                }
                move = validMoves[randomNumberGenerator(validMoves.length)];
                var squareToMoveIndex = squares.indexOf(move);
                squares[emptySquareIndex] = move;
                squares[squareToMoveIndex] = PuzzleState.emptySquare;
                emptySquareIndex = squareToMoveIndex;
            }
        }
        return puzzleState;
    };
    PuzzleState.prototype.getSquare = function (row, col) {
        if (row >= this.rows || row < 0) {
            throw new Exception(PuzzleState.invalidRowErrorCode, "The row argument is out of range");
        }
        if (col >= this.cols || col < 0) {
            throw new Exception(PuzzleState.invalidColErrorCode, "The col argument is out of range");
        }
        var cell = row * this.cols + col;
        return this.priSquares[cell];
    };
    PuzzleState.prototype.getSquareAtCell = function (cell) {
        if (cell >= this.length || cell < 0) {
            throw new Exception(PuzzleState.invalidCellErrorCode, "The cell argument is out of range");
        }
        return this.priSquares[cell];
    };
    PuzzleState.prototype.getRowAndColOfSquare = function (square) {
        var cell = this.priSquares.indexOf(square);
        if (cell === -1) {
            throw new Exception(PuzzleState.invalidSquareErrorCode, "The square argument is out of range");
        }
        var row = Math.floor(cell / this.priCols);
        var col = cell % this.priCols;
        return [row, col];
    };
    PuzzleState.prototype.getCellOfSquare = function (square) {
        var cell = this.priSquares.indexOf(square);
        if (cell === -1) {
            throw new Exception(PuzzleState.invalidSquareErrorCode, "The square argument is out of range");
        }
        return cell;
    };
    PuzzleState.prototype.move = function (move) {
        return PuzzleState.fromMoves(this, [move]);
    };
    PuzzleState.prototype.stringify = function () {
        if (this.priStringified === undefined) {
            this.priStringified = this.getStringified();
        }
        return this.priStringified;
    };
    PuzzleState.prototype.getIsSolved = function () {
        if (this.priSquares[this.length - 1] !== PuzzleState.emptySquare) {
            return false;
        }
        for (var i = this.length - 2; i >= 0; i--) {
            if (this.priSquares[i] !== i) {
                return false;
            }
        }
        return true;
    };
    PuzzleState.prototype.getStringified = function () {
        return [
            this.priRows,
            this.priCols,
            this.priSquares.join(squareDelimiter)
        ].join(segmentDelimiter);
    };
    PuzzleState.prototype.getValidMoves = function () {
        var validMoves = [];
        var _a = this.getRowAndColOfSquare(PuzzleState.emptySquare), row = _a[0], col = _a[1];
        if (row > 0) {
            validMoves.push(this.getSquare(row - 1, col));
        }
        if (row < this.priRows - 1) {
            validMoves.push(this.getSquare(row + 1, col));
        }
        if (col > 0) {
            validMoves.push(this.getSquare(row, col - 1));
        }
        if (col < this.priCols - 1) {
            validMoves.push(this.getSquare(row, col + 1));
        }
        return validMoves.sort();
    };
    PuzzleState.emptySquare = -1;
    PuzzleState.invalidCellErrorCode = "PuzzleState/invalid-cell";
    PuzzleState.invalidRowErrorCode = "PuzzleState/invalid-row";
    PuzzleState.invalidColErrorCode = "PuzzleState/invalid-col";
    PuzzleState.invalidSizeErrorCode = "PuzzleState/invalid-size";
    PuzzleState.invalidMoveCountErrorCode = "PuzzleState/invalid-move-count";
    PuzzleState.invalidSquareErrorCode = "PuzzleState/invalid-square";
    PuzzleState.parseFailedErrorCode = "PuzzleState/parse-failed";
    return PuzzleState;
}());
export { PuzzleState };
