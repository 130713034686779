import React from "react";

import "./styles.css";

// ------------------
// Internal Constants
// ------------------

const defaultColor = "white";

// --------------
// Exported Props
// --------------

export interface ITimeLabelProps {
  color?: string;
  large?: boolean;
  label?: string;
}

// -------------------
// Exported Components
// -------------------

export const TimeLabel: React.FC<ITimeLabelProps> = ({
  color,
  large,
  label
}) => {
  color = color || defaultColor;

  const [minutes, seconds] = label ? label.split(":") : ["0", "00"];
  return (
    <div className={`time-label${large ? "-large" : ""}`}>
      <div className={`time-label${large ? "-large" : ""}-digit`}>
        {minutes}
      </div>
      :
      <div className={`time-label${large ? "-large" : ""}-digit`}>
        {seconds[0]}
      </div>
      <div className={`time-label${large ? "-large" : ""}-digit`}>
        {seconds[1]}
      </div>
    </div>
  );
};
