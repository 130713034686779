/* tslint:disable:no-submodule-imports */
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
/* tslint:enable:no-submodule-imports */

import { ConfigService } from "./ConfigService";

firebase.initializeApp({
  apiKey: ConfigService.apiKey,
  appId: ConfigService.appId,
  authDomain: ConfigService.authDomain,
  projectId: ConfigService.projectId
});

if (ConfigService.local) {
  firebase.functions().useFunctionsEmulator(ConfigService.localUrl);
}

export const FirebaseService = firebase;
