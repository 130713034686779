import { Exception, Puzzle } from "shared";

// ------------------
// Internal Constants
// ------------------

const maxSolveDim = 0.2;
const maxFreezeDim = 0.3;

// --------------
// Internal Types
// --------------

interface ICellLayout {
  left: number;
  top: number;
}

enum MoveDirection {
  up,
  left,
  down,
  right
}

// --------------
// Exported Enums
// --------------

export enum SwipeDirection {
  left = "Left",
  right = "Right",
  up = "Up",
  down = "Down"
}

// ----------------
// Exported Classes
// ----------------

export class PuzzleViewModel {
  get containerSize() {
    return this.priContainerSize;
  }

  get containerStyle() {
    return this.priContainerStyle;
  }

  get canvasSize() {
    return this.priCanvasSize;
  }

  get canvasStyle() {
    return this.priCanvasStyle;
  }

  public static noCanvasContextErrorCode: string =
    "PuzzleViewModel/no-canvas-context";

  public static create(
    puzzle: Puzzle,
    canvas: HTMLCanvasElement,
    image: HTMLImageElement,
    containerSize: number,
    borderSize: number,
    backgroundColor: string,
    gridColor: string,
    animationRate: number,
    freeze: boolean,
    onSolved?: (moves: number[]) => void,
    onMove?: (moves: number[]) => void,
    onSolvedAnimationDone?: () => void,
    displayOnly: boolean = false
  ): PuzzleViewModel {
    const context = canvas.getContext("2d");
    if (!context) {
      throw new Exception(
        PuzzleViewModel.noCanvasContextErrorCode,
        "Failed to get to 2d context for the canvas"
      );
    }
    return new PuzzleViewModel(
      puzzle,
      canvas,
      context,
      image,
      containerSize,
      borderSize,
      backgroundColor,
      gridColor,
      animationRate,
      freeze,
      onSolved,
      onMove,
      onSolvedAnimationDone,
      displayOnly
    );
  }
  private priPuzzle: Puzzle;
  private priImage: HTMLImageElement;
  private priBorderSize: number;
  private priCellCount: number;
  private priContainerSize: number;
  private priContainerStyle: { width: number; height: number };
  private priCanvasSize: number;
  private priCanvasStyle: { width: number; height: number };
  private priCanvasCellSize: number;
  private priCanvasLayout: ICellLayout[];
  private priImageCellSize: number;
  private priImageSize: number;
  private priImageLayout: ICellLayout[];
  private priCanvas: HTMLCanvasElement;
  private pri2DContext: CanvasRenderingContext2D;
  private priBufferedMoves: number[] = [];
  private priAnimating: boolean = false;
  private priAnimationRate: number;
  private priBackgroundColor: string;
  private priGridColor: string;
  private priCancelAnimation: boolean;
  private priFrozen: boolean;
  private priFrozenDim: number;
  private priSolvedOpacity: number;
  private priSwiping: boolean;
  private priSwipeSquare: number;
  private priSwipeOffset: number;
  private priSwipeDirection: SwipeDirection;
  private priOnSolved?: (moves: number[]) => void;
  private priOnMove?: (moves: number[]) => void;
  private priOnSolvedAnimationDone?: () => void;
  private priDisplayOnly: boolean;

  private constructor(
    puzzle: Puzzle,
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    image: HTMLImageElement,
    containerSize: number,
    borderSize: number,
    backgroundColor: string,
    gridColor: string,
    animationRate: number,
    freeze: boolean,
    onSolved?: (moves: number[]) => void,
    onMove?: (moves: number[]) => void,
    onSolvedAnimationDone?: () => void,
    displayOnly: boolean = false
  ) {
    this.priPuzzle = puzzle;
    this.priCanvas = canvas;
    this.pri2DContext = context;
    this.priImage = image;
    this.priBorderSize = borderSize;
    this.priBackgroundColor = backgroundColor;
    this.priGridColor = gridColor;
    this.priAnimationRate = animationRate;
    this.priOnSolved = onSolved;
    this.priOnMove = onMove;
    this.priOnSolvedAnimationDone = onSolvedAnimationDone;
    this.priCancelAnimation = false;
    this.priFrozen = freeze;
    this.priFrozenDim = 0;
    this.priSolvedOpacity = 0;
    this.priSwiping = false;
    this.priSwipeDirection = SwipeDirection.up;
    this.priSwipeSquare = 0;
    this.priSwipeOffset = 0;
    this.priDisplayOnly = displayOnly;

    this.priContainerSize = containerSize;
    this.priContainerStyle = {
      height: this.priContainerSize,
      width: this.priContainerSize
    };

    this.priCellCount = puzzle.rows;
    this.priCanvasCellSize = Math.floor(
      (this.priContainerSize - 2 * this.priBorderSize) / this.priCellCount
    );
    this.priCanvasSize = this.priCanvasCellSize * this.priCellCount;
    this.priCanvasStyle = {
      height: this.priCanvasSize,
      width: this.priCanvasSize
    };
    this.priCanvasLayout = this.getCellLayout(
      this.priCellCount,
      this.priCanvasCellSize
    );

    this.priImageCellSize = Math.floor(image.width / this.priCellCount);
    this.priImageSize = this.priImageCellSize * this.priCellCount;
    this.priImageLayout = this.getCellLayout(
      this.priCellCount,
      this.priImageCellSize
    );
  }

  public click(x: number, y: number) {
    if (!this.priPuzzle.isSolved && !this.priFrozen && !this.priDisplayOnly) {
      const rect = this.priCanvas.getBoundingClientRect();
      x = x - rect.left;
      y = y - rect.top;

      let clickedCell = 0;
      for (let i = 0; i < this.priCanvasLayout.length; i++) {
        const cell = this.priCanvasLayout[i];
        if (
          x >= cell.left &&
          x < cell.left + this.priCanvasCellSize &&
          y >= cell.top &&
          y < cell.top + this.priCanvasCellSize
        ) {
          clickedCell = i;
          i = this.priCanvasLayout.length;
        }
      }

      const square = this.priPuzzle.getSquareAtCell(clickedCell);
      this.priBufferedMoves.push(square);
      this.animateMove();
    }
  }

  public swipe(
    deltaX: number,
    deltaY: number,
    direction: SwipeDirection,
    done: boolean
  ) {
    if (
      !this.priDisplayOnly &&
      !this.priFrozen &&
      !this.priPuzzle.isSolved &&
      !this.priAnimating &&
      !this.priBufferedMoves.length
    ) {
      if (!this.priSwiping) {
        let square: number | undefined;
        const [row, col] = this.priPuzzle.getRowAndColOfSquare(
          Puzzle.emptySquare
        );
        if (direction === SwipeDirection.up && row + 1 < this.priPuzzle.rows) {
          square = this.priPuzzle.getSquare(row + 1, col);
        } else if (direction === SwipeDirection.down && row > 0) {
          square = this.priPuzzle.getSquare(row - 1, col);
        } else if (direction === SwipeDirection.right && col > 0) {
          square = this.priPuzzle.getSquare(row, col - 1);
        } else if (
          direction === SwipeDirection.left &&
          col + 1 < this.priPuzzle.cols
        ) {
          square = this.priPuzzle.getSquare(row, col + 1);
        }
        if (
          square !== undefined &&
          this.priPuzzle.validMoves.includes(square)
        ) {
          this.priSwiping = true;
          this.priSwipeSquare = square;
          this.priSwipeDirection = direction;
        }
      }

      if (this.priSwiping) {
        let delta =
          this.priSwipeDirection === SwipeDirection.right ? -deltaX : deltaX;

        if (this.priSwipeDirection === SwipeDirection.down) {
          delta = -deltaY;
        } else if (this.priSwipeDirection === SwipeDirection.up) {
          delta = deltaY;
        }
        this.priSwipeOffset =
          delta > this.priCanvasCellSize ? this.priCanvasCellSize : delta;
        if (this.priSwipeOffset < 0) {
          this.priSwipeOffset = 0;
        }
        this.drawSwipe();
        if (done) {
          if (this.priSwipeOffset < this.priCanvasCellSize / 2) {
            this.priPuzzle.move(this.priSwipeSquare);
            this.priSwipeOffset = this.priCanvasCellSize - this.priSwipeOffset;
          }
          this.priBufferedMoves.push(this.priSwipeSquare);
          this.priSwiping = false;
          this.animateMove();
        }
      }
    }
  }

  public move(square: number) {
    if (!this.priFrozen) {
      this.priBufferedMoves.push(square);
      this.animateMove();
    }
  }

  public freeze() {
    if (!this.priFrozen) {
      this.priFrozen = true;
      this.draw();
    }
  }

  public draw() {
    this.priCancelAnimation = true;
    for (let cell = 0; cell < this.priPuzzle.length; cell++) {
      this.drawSquare(cell);
    }

    if (!this.priDisplayOnly) {
      if (this.priPuzzle.isSolved) {
        this.pri2DContext.globalAlpha = this.priSolvedOpacity;
        this.drawSolved();
        this.pri2DContext.globalAlpha = 1;
        this.pri2DContext.fillStyle = `rgba(0,0,0, ${this.priFrozenDim})`;
        this.pri2DContext.fillRect(
          0,
          0,
          this.priCanvasSize,
          this.priCanvasSize
        );
        if (this.priSolvedOpacity < 1 || this.priFrozenDim < maxSolveDim) {
          requestAnimationFrame(() => {
            if (this.priSolvedOpacity < 1) {
              this.priSolvedOpacity += 0.005;
            }
            if (this.priFrozenDim < maxSolveDim) {
              this.priFrozenDim += 0.005;
            }
            this.draw();
          });
        } else if (this.priOnSolvedAnimationDone) {
          this.priOnSolvedAnimationDone();
        }
      } else if (this.priFrozen) {
        this.pri2DContext.fillStyle = `rgba(0,0,0, ${this.priFrozenDim})`;
        this.pri2DContext.fillRect(
          0,
          0,
          this.priCanvasSize,
          this.priCanvasSize
        );
        if (this.priFrozenDim < maxFreezeDim) {
          requestAnimationFrame(() => {
            this.priFrozenDim += 0.005;
            this.draw();
          });
        }
      }
    }

    this.priCancelAnimation = false;
  }

  private drawSwipe() {
    const toCell = this.priPuzzle.getCellOfSquare(Puzzle.emptySquare);
    const fromCell = this.priPuzzle.getCellOfSquare(this.priSwipeSquare);
    let direction = MoveDirection.up;
    if (fromCell + 1 === toCell) {
      direction = MoveDirection.left;
    } else if (fromCell - 1 === toCell) {
      direction = MoveDirection.right;
    } else if (fromCell > toCell) {
      direction = MoveDirection.down;
    }

    this.clearSquare(fromCell);
    this.clearSquare(toCell);
    switch (direction) {
      case MoveDirection.left:
        this.drawSquare(fromCell, this.priSwipeOffset, 0);
        break;
      case MoveDirection.right:
        this.drawSquare(fromCell, -this.priSwipeOffset, 0);
        break;
      case MoveDirection.down:
        this.drawSquare(fromCell, 0, -this.priSwipeOffset);
        break;
      default:
        this.drawSquare(fromCell, 0, this.priSwipeOffset);
    }
  }

  private animateMove() {
    if (
      !this.priAnimating &&
      this.priBufferedMoves.length &&
      !this.priPuzzle.isSolved
    ) {
      const square = this.priBufferedMoves.shift() as number;

      if (!this.priPuzzle.tryMove(square)) {
        this.animateMove();
      } else {
        if (this.priOnMove) {
          this.priOnMove(this.priPuzzle.pastMoves);
        }
        const toCell = this.priPuzzle.getCellOfSquare(square);
        const fromCell = this.priPuzzle.getCellOfSquare(Puzzle.emptySquare);
        let offset = this.priCanvasCellSize - this.priSwipeOffset;

        let direction = MoveDirection.up;
        if (fromCell + 1 === toCell) {
          direction = MoveDirection.left;
        } else if (fromCell - 1 === toCell) {
          direction = MoveDirection.right;
        } else if (fromCell > toCell) {
          direction = MoveDirection.down;
        }

        const animationRate = Math.floor(
          this.priCanvasCellSize / this.priAnimationRate
        );

        const executeAnimation = () => {
          if (!this.priCancelAnimation) {
            requestAnimationFrame(() => {
              if (offset > 0) {
                offset = offset - animationRate;
                if (offset < 0) {
                  offset = 0;
                }
                this.draw();
                this.clearSquare(fromCell);
                this.clearSquare(toCell);
                switch (direction) {
                  case MoveDirection.left:
                    this.drawSquare(toCell, -offset, 0);
                    break;
                  case MoveDirection.right:
                    this.drawSquare(toCell, offset, 0);
                    break;
                  case MoveDirection.down:
                    this.drawSquare(toCell, 0, offset);
                    break;
                  default:
                    this.drawSquare(toCell, 0, -offset);
                }
                executeAnimation();
              } else {
                this.priAnimating = false;
                this.priSwipeOffset = 0;
                if (this.priPuzzle.isSolved) {
                  if (this.priOnSolved) {
                    this.priOnSolved(this.priPuzzle.pastMoves);
                  }
                  this.draw();
                } else {
                  this.animateMove();
                }
              }
            });
          }
        };

        this.priAnimating = true;
        executeAnimation();
      }
    }
  }

  private drawSolved() {
    this.pri2DContext.drawImage(
      this.priImage,
      0,
      0,
      this.priImageSize,
      this.priImageSize,
      0,
      0,
      this.priCanvasSize,
      this.priCanvasSize
    );
  }

  private clearSquare(cell: number) {
    const canvasLayout = this.priCanvasLayout[cell];

    this.pri2DContext.save();
    this.pri2DContext.beginPath();
    this.pri2DContext.moveTo(canvasLayout.left, canvasLayout.top);
    this.pri2DContext.lineTo(
      canvasLayout.left + this.priCanvasCellSize,
      canvasLayout.top
    );
    this.pri2DContext.lineTo(
      canvasLayout.left + this.priCanvasCellSize,
      canvasLayout.top + this.priCanvasCellSize
    );
    this.pri2DContext.lineTo(
      canvasLayout.left,
      canvasLayout.top + this.priCanvasCellSize
    );
    this.pri2DContext.closePath();
    this.pri2DContext.clip();

    this.pri2DContext.fillStyle = this.priBackgroundColor;

    this.pri2DContext.clearRect(
      canvasLayout.left,
      canvasLayout.top,
      this.priCanvasCellSize,
      this.priCanvasCellSize
    );

    this.pri2DContext.fillRect(
      canvasLayout.left,
      canvasLayout.top,
      this.priCanvasCellSize,
      this.priCanvasCellSize
    );

    this.pri2DContext.shadowColor = "black";
    this.pri2DContext.shadowBlur = 5;
    this.pri2DContext.lineWidth = 3;
    this.pri2DContext.strokeRect(
      canvasLayout.left - 2.5,
      canvasLayout.top - 2.5,
      this.priCanvasCellSize + 5,
      this.priCanvasCellSize + 5
    );

    this.pri2DContext.shadowBlur = 0;
    this.pri2DContext.restore();

    this.drawGridLines(cell);
  }

  private drawGridLine(fromX: number, fromY: number, toX: number, toY: number) {
    this.pri2DContext.lineWidth = 0.5;
    this.pri2DContext.strokeStyle = this.priGridColor;
    this.pri2DContext.beginPath();
    this.pri2DContext.moveTo(fromX + 0.5, fromY + 0.5);
    this.pri2DContext.lineTo(toX + 0.5, toY + 0.5);
    this.pri2DContext.stroke();
  }

  private drawGridLines(cell: number, left: number = 0, top: number = 0) {
    const canvasLayout = this.priCanvasLayout[cell];
    const isLeftCell = cell % this.priPuzzle.rows === 0;
    const isTopCell = cell < this.priPuzzle.cols;
    if (!isLeftCell || left > 0) {
      this.drawGridLine(
        canvasLayout.left + left,
        canvasLayout.top + top,
        canvasLayout.left + left,
        canvasLayout.top + top + this.priCanvasCellSize
      );
    }

    if (!isTopCell || top > 0) {
      this.drawGridLine(
        canvasLayout.left + left,
        canvasLayout.top + top,
        canvasLayout.left + left + this.priCanvasCellSize,
        canvasLayout.top + top
      );
    }

    if (left !== 0 && Math.abs(left) < this.priCanvasCellSize) {
      this.drawGridLine(
        canvasLayout.left + left + this.priCanvasCellSize,
        canvasLayout.top + top,
        canvasLayout.left + left + this.priCanvasCellSize,
        canvasLayout.top + top + this.priCanvasCellSize
      );
    }

    if (top !== 0 && Math.abs(top) < this.priCanvasCellSize) {
      this.drawGridLine(
        canvasLayout.left + left,
        canvasLayout.top + top + this.priCanvasCellSize,
        canvasLayout.left + left + this.priCanvasCellSize,
        canvasLayout.top + top + this.priCanvasCellSize
      );
    }
  }

  private drawSquare(cell: number, left: number = 0, top: number = 0) {
    const square = this.priPuzzle.getSquareAtCell(cell);
    const canvasLayout = this.priCanvasLayout[cell];

    if (square !== Puzzle.emptySquare) {
      const imageLayout = this.priImageLayout[square];
      this.pri2DContext.drawImage(
        this.priImage,
        imageLayout.left,
        imageLayout.top,
        this.priImageCellSize,
        this.priImageCellSize,
        canvasLayout.left + left,
        canvasLayout.top + top,
        this.priCanvasCellSize,
        this.priCanvasCellSize
      );
    } else {
      this.clearSquare(cell);
    }
    this.drawGridLines(cell, left, top);
  }

  private getCellLayout(cellCount: number, cellSize: number): ICellLayout[] {
    const cells: ICellLayout[] = [];
    for (let row = 0; row < cellCount; row++) {
      for (let col = 0; col < cellCount; col++) {
        cells.push({
          left: col * cellSize,
          top: row * cellSize
        });
      }
    }

    return cells;
  }
}
