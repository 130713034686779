import React, { useEffect, useState } from "react";
import { ClientService } from "../../services";
import { Fade } from "../Fade";
import { Logo } from "../Logo";
import "./styles.css";

// -------------------
// Exported Interfaces
// -------------------

export interface ILoaderScreenProps {
  show?: boolean;
  fadeIn?: boolean;
  fadeOut?: boolean;
  onFadeChange?: (fadeIn: boolean) => void;
}

// -------------------
// Exported Components
// -------------------

export const LoaderScreen: React.FC<ILoaderScreenProps> = ({
  show,
  fadeIn,
  fadeOut,
  onFadeChange
}) => {
  const [game, setGame] = useState(ClientService.default.game);
  const [logoExpanded, setLogoExpanded] = useState(false);
  const [showLogo, setShowLogo] = useState(show);
  const [iconOnly, setIconOnly] = useState(true);

  useEffect(() => {
    ClientService.default.onGameChanged(setGame);
    if (game !== ClientService.default.game) {
      setGame(ClientService.default.game);
    }

    let timeoutInner: NodeJS.Timeout;
    const timeoutOutter = setTimeout(() => {
      setIconOnly(false);
      timeoutInner = setTimeout(() => {
        setLogoExpanded(true);
      }, 1500);
    }, 1500);

    return () => {
      ClientService.default.offGameChanged(setGame);
      clearTimeout(timeoutOutter);
      if (timeoutInner) {
        clearTimeout(timeoutInner);
      }
    };
    // eslint-disable-next-line
  }, []);

  if (logoExpanded && game && showLogo) {
    setShowLogo(false);
  }

  return (
    <Fade
      fadeIn={fadeIn === undefined ? false : fadeIn}
      fadeOut={fadeOut === undefined ? false : fadeOut}
      show={showLogo}
      onFadeChange={onFadeChange}
    >
      <div className="loader">
        <div className="loader-logo">
          <Logo iconOnly={iconOnly} />
        </div>
      </div>
    </Fade>
  );
};
