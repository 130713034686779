import React from "react";

import "./styles.css";

// ------------------
// Internal Constants
// ------------------

const defaultColor = "white";

// --------------
// Exported Props
// --------------

export interface IExitProps {
  color?: string;
  onClick?: () => void;
}

// -------------------
// Exported Components
// -------------------

export const Exit: React.FC<IExitProps> = ({ color, onClick }) => {
  color = color || defaultColor;
  return (
    <div className="exit" tabIndex={-1} onClick={onClick}>
      <div className="exit-icon">
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 512 512"
          fillRule="evenodd"
          clipRule="evenodd"
          strokeMiterlimit="2"
          pointerEvents="none"
        >
          <path
            fill={color}
            d="M450,243L382.063,174.3L400,155L495,256L493.627,256.397L399,357L381.063,337.7L449,269L223,269L223,243L450,243Z"
          />

          <path
            fill={color}
            d="M352,487L25,487L25,25L352,25L352,64.001L377,64.001L377,0L368,0L0,0L0,512L377,512L377,448L352,448L352,487Z"
          />
        </svg>
      </div>
    </div>
  );
};
