// ----------------
// Exported Classes
// ----------------
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Exception = /** @class */ (function (_super) {
    __extends(Exception, _super);
    function Exception(code, message) {
        var _newTarget = this.constructor;
        var _this = this;
        if (message) {
            if (message[0] === "[") {
                var index = message.indexOf("]: ");
                if (index !== -1) {
                    var codeFromMessage = message.substr(1, index - 1);
                    if (!code || code === codeFromMessage) {
                        code = codeFromMessage;
                        message = message.substr(index + 3);
                    }
                }
            }
        }
        _this = _super.call(this, message) || this;
        Object.setPrototypeOf(_this, _newTarget.prototype);
        _this.priCode = code || "unknown";
        return _this;
    }
    Object.defineProperty(Exception.prototype, "code", {
        get: function () {
            return this.priCode;
        },
        enumerable: true,
        configurable: true
    });
    return Exception;
}(Error));
export { Exception };
