import React, { useEffect, useRef, useState } from "react";
import { Puzzle } from "shared";
import { useResize } from "../../hooks";
import { ClientService, ConfigService } from "../../services";
import { Fade } from "../Fade";
import { Logo } from "../Logo";
import { PuzzleBoard } from "../PuzzleBoard";
import "./styles.css";

// ------------------
// Internal Constants
// ------------------

const puzzle = Puzzle.parse(
  "5:5:-1,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,0,20,21,22,23"
);

// -------------------
// Exported Interfaces
// -------------------

export interface ISplashScreenProps {
  show?: boolean;
  fadeIn?: boolean;
  fadeOut?: boolean;
  height?: number;
  onFadeChange?: (fadeIn: boolean) => void;
}

// -------------------
// Exported Components
// -------------------

export const SplashScreen: React.FC<ISplashScreenProps> = ({
  show,
  fadeIn,
  fadeOut,
  height,
  onFadeChange
}) => {
  const [game, setGame] = useState(ClientService.default.game);
  const container = useRef<HTMLDivElement>(null);
  const [showSplash, setShowSplash] = useState(show);
  const [buttonLabel, setButtonLabel] = useState("Play Now");
  const [image, setImage] = useState();
  const containerSize = useResize(container);

  const canvasSize =
    window.innerWidth >= 640 ? window.innerHeight : containerSize.width;

  useEffect(() => {
    ClientService.default.onGameChanged(setGame);
    const newImage = new Image();
    newImage.onload = () => setImage(newImage);
    newImage.src = ConfigService.splashImageUrl;
    return () => {
      ClientService.default.offGameChanged(setGame);
    };
  }, []);

  function onMouseUp() {
    ClientService.default.fetchGame();
    setTimeout(() => {
      setButtonLabel("Loading...");
    }, 2000);
  }

  if (game && showSplash) {
    setShowSplash(false);
  }

  return (
    <Fade
      fadeIn={fadeIn === undefined ? false : fadeIn}
      fadeOut={fadeOut === undefined ? false : fadeOut}
      show={showSplash}
      onFadeChange={onFadeChange}
    >
      <div className="splash" style={height ? { height } : {}}>
        <div className="splash-left">
          <div className="splash-logo">
            <Logo />
          </div>

          <div className="splash-left-inner">
            <div>
              <div className="splash-title">Puzzle Slider</div>
              <div className="splash-subtitle">
                <i>
                  Can you slide the last square into place before time runs out?
                </i>
              </div>
              <div className="splash-free">
                100% Free to Play. No In-App Purchases.
              </div>
            </div>

            <div className="splash-play-now-button-container">
              <div className="splash-play-now-button" onMouseUp={onMouseUp}>
                {buttonLabel}
              </div>
            </div>
          </div>
        </div>

        <div className="splash-right" ref={container}>
          <PuzzleBoard
            image={image}
            square={{ size: canvasSize, top: 0, left: 0 }}
            puzzle={puzzle}
            borderSize={canvasSize * 0.05}
            animateRate={25}
            automated
            displayOnly
          />
        </div>
      </div>
    </Fade>
  );
};
