import React, { useEffect, useState } from "react";

// ------------------
// Internal Constants
// ------------------

const maxOpacity = 100;
const minOpacity = 0;

// ------------------
// Internal Functions
// ------------------

function updateOpacity(opacity: number, increment: boolean, rate: number) {
  opacity += increment ? rate : -rate;
  if (opacity > maxOpacity) {
    opacity = maxOpacity;
  }
  if (opacity < minOpacity) {
    opacity = minOpacity;
  }
  return opacity;
}

// -------------------
// Exported Interfaces
// -------------------

export interface IFadeProps {
  fadeOut?: boolean;
  fadeIn?: boolean;
  fadeRate?: number;
  show?: boolean;
  ensureLayout?: boolean;
  onFadeChange?: (fadeIn: boolean) => void;
}

// -------------------
// Exported Components
// -------------------

export const Fade: React.FC<IFadeProps> = ({
  show,
  fadeOut,
  fadeIn,
  fadeRate,
  onFadeChange,
  ensureLayout,
  children
}) => {
  const [visibleLast, setVisibleLast] = useState(false);
  const [opacity, setOpacity] = useState(minOpacity);

  fadeRate = fadeRate || 3;
  if (show !== visibleLast) {
    if (show && !fadeIn) {
      setOpacity(maxOpacity);
    }
    if (!show && !fadeOut) {
      setOpacity(minOpacity);
    }
    setVisibleLast(show || false);
  }

  useEffect(() => {
    let animationFrameRequst: number = 0;
    if (opacity >= minOpacity && opacity <= maxOpacity) {
      animationFrameRequst = window.requestAnimationFrame(() => {
        const newOpacity = updateOpacity(
          opacity,
          show || false,
          fadeRate as number
        );

        if (opacity !== newOpacity) {
          setOpacity(newOpacity);
          if (newOpacity === maxOpacity && onFadeChange) {
            onFadeChange(true);
          } else if (newOpacity === minOpacity && onFadeChange) {
            onFadeChange(false);
          }
        }
      });
    }

    if (animationFrameRequst) {
      return () => {
        window.cancelAnimationFrame(animationFrameRequst);
      };
    }
  }, [opacity, show, fadeRate, onFadeChange]);

  return (
    <div
      style={{
        display: opacity === minOpacity && !ensureLayout ? "none" : undefined,
        height: "100%",
        opacity: opacity / 100
      }}
    >
      {children}
    </div>
  );
};
