import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { Color, Puzzle } from "shared";
import { PuzzleViewModel } from "../../viewModels/PuzzleViewModel";
import { Fade } from "../Fade";
import { ISquareRect } from "../SquareContainer";
import "./styles.css";

// ------------------
// Internal Constants
// ------------------

const glowRate = 0.03;
const defaultBorderSize = 20;
const noCanvasSize = 0;
const noWidthHeightStyle = {
  boxShadow: "",
  height: noCanvasSize,
  visibility: "hidden" as "hidden",
  width: noCanvasSize
};
const defaultAnimateRate = 8;

// -------------------
// Exported Interfaces
// -------------------

export interface IPuzzleProps {
  puzzle?: Puzzle;
  image?: HTMLImageElement;
  square?: ISquareRect;
  borderSize?: number;
  animateRate?: number;
  automated?: boolean;
  freeze?: boolean;
  freezeOverlayLabel?: string;
  displayOnly?: boolean;
  glowColors?: Color[];
  onSolved?: (moves: number[]) => void;
  onMove?: (moves: number[]) => void;
  onSolvedAnimationDone?: () => void;
}

// -------------------
// Exported Components
// -------------------

export const PuzzleBoard: React.FC<IPuzzleProps> = ({
  puzzle,
  image,
  square,
  borderSize,
  animateRate,
  automated,
  freeze,
  freezeOverlayLabel,
  displayOnly,
  onSolved,
  onMove,
  onSolvedAnimationDone
}) => {
  const canvas = useRef<HTMLCanvasElement>(null);
  const [viewModel, setViewmodel] = useState();
  const [isSolved, setIsSolved] = useState(false);
  const [glow, setGlow] = useState(0);
  const [glowDirection, setGlowDirection] = useState(1);

  function onSolvedWrapped(moves: number[]): void {
    setIsSolved(true);
    if (onSolved) {
      onSolved(moves);
    }
  }

  useLayoutEffect(() => {
    if (
      canvas.current &&
      square &&
      puzzle &&
      image &&
      (!viewModel || square.size !== viewModel.containerStyle.width)
    ) {
      setViewmodel(
        PuzzleViewModel.create(
          puzzle,
          canvas.current,
          image,
          square.size,
          borderSize === undefined ? defaultBorderSize : borderSize,
          "rgba(255, 255, 255, 0.3)",
          "rgba(255, 255, 255, 0.5)",
          animateRate || defaultAnimateRate,
          freeze || false,
          onSolvedWrapped,
          onMove,
          onSolvedAnimationDone,
          displayOnly || false
        )
      );
    }
    // eslint-disable-next-line
  }, [canvas, canvas.current, square, puzzle]);

  function onMouseDown(context: any) {
    if (viewModel) {
      viewModel.click(context.clientX, context.clientY);
    }
  }

  useEffect(() => {
    let requestedAnimationFrame: number;
    if (isSolved) {
      if (glowDirection > 0) {
        if (glow < 0.8) {
          requestedAnimationFrame = requestAnimationFrame(() => {
            setGlow(glow + glowRate);
          });
        } else {
          setGlowDirection(glowDirection * -1);
        }
      } else {
        if (glow > 0.2) {
          requestedAnimationFrame = requestAnimationFrame(() => {
            setGlow(glow - glowRate);
          });
        }
      }
    }
    return () => {
      if (requestedAnimationFrame) {
        cancelAnimationFrame(requestedAnimationFrame);
      }
    };
  }, [glow, isSolved, glowDirection]);

  useLayoutEffect(() => {
    if (viewModel && freeze) {
      viewModel.freeze();
    }
  }, [viewModel, freeze]);

  useLayoutEffect(() => {
    let nextTimeout: NodeJS.Timeout;
    if (viewModel) {
      viewModel.draw();
      if (automated && puzzle) {
        let count = 0;
        const animate = () => {
          nextTimeout = setTimeout(() => {
            if (!document.hidden && count === 100) {
              count = 0;
              viewModel.move(puzzle.getRandomMove());
            } else {
              count++;
              viewModel.draw();
            }
            animate();
          }, 10);
        };
        animate();
      }
    }

    return () => {
      if (nextTimeout) {
        clearTimeout(nextTimeout);
      }
    };
  }, [viewModel, puzzle, automated]);

  let canvasStyle = noWidthHeightStyle;
  if (viewModel) {
    let margin = (square ? square.left : 0) + (borderSize || 0);
    if (margin > 300) {
      margin = 300;
    }
    if (margin < 100) {
      margin = 100;
    }

    canvasStyle = {
      boxShadow: isSolved
        ? `0 0 ${glow * 0.3 * margin}px ${glow * 0.1 * margin}px #fff`
        : "",
      height: viewModel.canvasStyle.height,
      // @ts-ignore
      visibility: image ? ("visible" as "visible") : "hidden",
      width: viewModel.canvasStyle.width
    };
  }

  const handlers = useSwipeable({
    onSwiped: event => {
      if (viewModel) {
        viewModel.swipe(event.deltaX, event.deltaY, event.dir, true);
      }
    },
    onSwiping: event => {
      if (viewModel) {
        viewModel.swipe(event.deltaX, event.deltaY, event.dir, false);
      }
    },
    preventDefaultTouchmoveEvent: true
  });

  return (
    <div
      {...handlers}
      style={viewModel ? viewModel.containerStyle : noWidthHeightStyle}
      className="puzzle-container"
      tabIndex={-1}
    >
      <canvas
        ref={canvas}
        className={automated || displayOnly ? "cursor-off" : "cursor-on"}
        style={canvasStyle}
        width={viewModel ? viewModel.canvasSize : noCanvasSize}
        height={viewModel ? viewModel.canvasSize : noCanvasSize}
        onMouseDown={automated || displayOnly ? undefined : onMouseDown}
        tabIndex={-1}
      ></canvas>

      <Fade fadeIn show={freeze || false}>
        <div
          className="puzzle-freeze"
          style={
            viewModel
              ? {
                  height: viewModel.canvasSize,
                  left: borderSize,
                  top: borderSize,
                  width: viewModel.canvasSize
                }
              : {}
          }
        >
          <div className="puzzle-freeze-label">{freezeOverlayLabel}</div>
        </div>
      </Fade>
    </div>
  );
};
