import { useLayoutEffect, useState } from "react";
import ResizeObserver from "resize-observer-polyfill";

// -------------
// Exported hook
// -------------

export default function useResize(
  element: React.RefObject<any>
): { width: number; height: number } {
  const [size, setSize] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    let observer: ResizeObserver | undefined;
    const htmlElement = element ? element.current : undefined;

    function onWindowResize() {
      window.removeEventListener("resize", onWindowResize);
      if (htmlElement) {
        const { width, height } = htmlElement.getBoundingClientRect();
        const newSize = { width, height };
        if (size.width !== newSize.width || size.height !== newSize.height) {
          setSize({ width: newSize.width, height: newSize.height });
        }
      }
    }

    if (htmlElement) {
      const { width, height } = htmlElement.getBoundingClientRect();
      const newSize = { width, height };
      setSize(newSize);

      observer = new ResizeObserver(entries => {
        for (const entry of entries) {
          const newRect = entry.contentRect;
          if (size.width !== newRect.width || size.height !== newRect.height) {
            setSize({ width: newRect.width, height: newRect.height });
          }
        }
      });

      observer.observe(htmlElement);
      window.addEventListener("resize", onWindowResize);
    }

    return () => {
      if (observer && htmlElement) {
        observer.unobserve(htmlElement);
        window.removeEventListener("rise", onWindowResize);
      }
    };
  }, [element, size.height, size.width]);

  return size;
}
