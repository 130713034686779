// ------------------
// Internal Constants
// ------------------

const defaultTimeout = 10 * 1000; // 10 seconds
const waitDefault = 50;
const propertyCheckQueue: IPropertyCheck[] = [];

// -------------
// Interal Types
// -------------

interface IPropertyCheck {
  resolve: (value?: unknown) => void;
  reject: (reason?: any) => void;
  url: string;
  windowProperty?: string;
  start: number;
  timeout: number;
}

// ------------------
// Internal Functions
// ------------------

function propertyExistsCheck({
  resolve,
  reject,
  url,
  windowProperty,
  start,
  timeout
}: IPropertyCheck): boolean {
  // @ts-ignore
  const windowObject = window[windowProperty];

  if (windowObject) {
    resolve(windowObject);
    return true;
  }

  const now = Date.now();
  if (now - start > timeout) {
    reject(new Error(`Timed out before script at '${url}' loaded`));
    return true;
  }

  return false;
}

function queueCheck() {
  for (let i = propertyCheckQueue.length - 1; i >= 0; i--) {
    const propertyCheck = propertyCheckQueue[i];

    const isComplete = propertyExistsCheck(propertyCheck);
    if (isComplete) {
      propertyCheckQueue.splice(i, 1);
    }
  }

  if (propertyCheckQueue.length) {
    setTimeout(queueCheck, waitDefault);
  }
}

// ------------------
// Exported Functions
// ------------------

export async function load(
  url: string,
  options: { windowProperty?: string; timeout?: number } = {}
): Promise<any> {
  const script = document.createElement("script");
  script.src = url;
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);

  const timeout = options.timeout || defaultTimeout;
  const start = Date.now();
  const windowProperty = options.windowProperty;

  return new Promise((resolve, reject) => {
    if (!options.windowProperty) {
      resolve();
      return;
    }

    propertyCheckQueue.push({
      reject,
      resolve,
      start,
      timeout,
      url,
      windowProperty
    });

    queueCheck();
  });
}
