import * as Script from "./script";

// ------------------
// Internal Constants
// ------------------

const scope = "profile email";

// ------------------
// Internal Variables
// ------------------

let gapi: any = null;

// ------------------
// Exported Functions
// ------------------

export async function init(): Promise<void> {
  if (!gapi) {
    gapi = await Script.load("https://apis.google.com/js/platform.js", {
      windowProperty: "gapi"
    });

    return new Promise((resolve, reject) => {
      gapi.load("auth2", async () => {
        try {
          await gapi.auth2.init({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID
          });
        } catch (error) {
          reject(error);
          return;
        }

        resolve();
      });
    });
  }
}

export async function login(): Promise<string | null> {
  if (!gapi) {
    await init();
  }

  let googleUser: any = null;

  try {
    const googleAuth = gapi.auth2.getAuthInstance();
    googleUser = googleAuth.isSignedIn.get()
      ? await googleAuth.currentUser.get()
      : await googleAuth.signIn({ scope });

    const authResponse = googleUser.getAuthResponse();
    return authResponse.id_token || null;
  } catch (error) {
    return null;
  }
}

export async function logout(): Promise<void> {
  if (!gapi) {
    await init();
  }

  try {
    const googleAuth = gapi.auth2.getAuthInstance();
    await googleAuth.signOut();
  } catch (error) {
    // do nothing
  }
}
