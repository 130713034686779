// --------------
// Exported Class
// --------------
var Random = /** @class */ (function () {
    function Random() {
    }
    Random.withMax = function (max) {
        return Math.floor(Math.random() * Math.floor(max));
    };
    return Random;
}());
export { Random };
