import chroma from "chroma-js";
import React, { useEffect, useRef, useState } from "react";
import { Color } from "shared";
import { ClientService, ConfigService } from "../../services";
import "./styles.css";

// ------------------
// Internal Constants
// ------------------

const sameColorDelta = 5;
const mixRatio = 0.3;
const topOpacity = 0.03;
const bodyColor = "#282c34";

// ------------------
// Internal Functions
// ------------------

function areSameColors(first: chroma.Color[], second: chroma.Color[]): boolean {
  if (first === second) {
    return true;
  }

  if (first.length !== second.length) {
    return false;
  }
  for (let i = 0; i < first.length; i++) {
    if (first[i].hex() !== second[i].hex()) {
      return false;
    }
  }

  return true;
}

function areCloseColors(first: chroma.Color, second: chroma.Color): boolean {
  return chroma.distance(first, second) < sameColorDelta;
}

function blend(first: chroma.Color[], second: chroma.Color[]): chroma.Color[] {
  const blended = [];
  for (let i = 0; i < first.length; i++) {
    blended.push(
      areCloseColors(first[i], second[i])
        ? second[i]
        : chroma.mix(first[i], second[i], mixRatio)
    );
  }
  return blended;
}

function getFavicons() {
  const favicons = [];
  const links = document
    .getElementsByTagName("head")[0]
    .getElementsByTagName("link");
  for (let i = links.length - 1; i >= 0; i--) {
    if (/(^|\s)icon(\s|$)/i.test(links[i].getAttribute("rel") || "")) {
      favicons.push(links[i]);
    }
  }
  return favicons;
}

function setFavicon(color: chroma.Color): void {
  const faviconImage = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
  <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
  <svg width="100%" height="100%" viewBox="0 0 236 236" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;">
      <path d="M236,23.58C236,10.566 225.434,0 212.42,0L23.58,0C10.566,0 0,10.566 0,23.58L0,212.42C0,225.434 10.566,236 23.58,236L212.42,236C225.434,236 236,225.434 236,212.42L236,23.58Z" style="fill:${color.hex()};"/>
      <path d="M34,69.792C34,69.355 34.355,69 34.792,69L108.208,69C108.645,69 109,69.355 109,69.792L109,95.208C109,95.645 108.645,96 108.208,96L68,96L68,109L97.208,109C97.645,109 98,109.355 98,109.792L98,135.208C98,135.645 97.645,136 97.208,136L68,136L68,166.003C68,166.553 67.553,167 67.003,167L34.997,167C34.447,167 34,166.553 34,166.003L34,69.792Z" style="fill:white;stroke:rgb(152,167,200);stroke-opacity:0;stroke-width:1.33px;"/>
      <path d="M119.514,93.135C119.389,79.76 128.2,68.073 150.803,68C159.328,67.972 191.176,68 191.176,68C193.483,68 195.166,68.495 196.223,69.486C197.28,70.477 197.809,72.054 197.809,74.216L197.809,90.297C197.809,92.459 197.28,94.036 196.223,95.027C196.152,95.094 194.596,96 194.596,96C193.672,96.342 192.532,96.513 191.176,96.514L157.58,96.514C156.811,96.514 156.21,96.784 155.778,97.324C155.345,97.865 155.129,98.495 155.129,99.216C155.129,100.027 155.345,100.703 155.778,101.243C156.21,101.784 156.859,102.099 157.724,102.189L179.209,104.892C183.061,105.421 186.501,106.304 189.53,107.541C189.53,107.541 194.694,110.123 196.511,111.581C200.473,114.758 202.621,119.805 202.954,126.722C202.984,127.016 202.618,127.02 203,147.384C203.201,158.114 191.423,167.564 174.208,168C173.376,168.021 126.147,168 126.147,168C126.147,168 122,167.645 122,167.208L121,166.416C120.009,165.421 119.514,163.877 119.514,161.784L119.514,145.973C119.514,143.811 120.043,142.234 121.1,141.243C122.157,140.252 123.84,139.757 126.147,139.757L164.934,139.757C165.703,139.757 166.32,139.346 166.664,138.946C167.008,138.546 167,137.897 167,137.355C167,136.679 167.033,135.46 166.664,134.892C166.296,134.324 165.655,134.036 164.79,133.946L143.161,131.108C140.22,130.704 137.523,130.093 135.069,129.277C132.107,128.529 129.496,127.238 127.468,125.569C126.936,125.202 126.423,124.819 125.93,124.419C121.653,120.95 119.514,115.252 119.514,107.324C119.514,107.324 119.549,96.836 119.514,93.135Z" style="fill:white;fill-rule:nonzero;stroke:white;stroke-width:1.33px;stroke-linecap:butt;stroke-miterlimit:2;"/>
  </svg>`;

  const svgBase64 = btoa(faviconImage);
  const svgImage = new Image();
  svgImage.onload = () => {
    for (const favicon of getFavicons()) {
      if (favicon && favicon.href) {
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = image.width;
          canvas.height = image.height;

          const context = canvas.getContext("2d");
          if (context) {
            context.drawImage(svgImage, 0, 0, image.width, image.height);
            favicon.href = canvas.toDataURL("image/png");
          }
        };

        image.src = favicon.href;
      }
    }
  };

  svgImage.src = "data:image/svg+xml;base64," + svgBase64;
}

// -------------------
// Exported Interfaces
// -------------------

export interface IBackgroundProps {
  height?: number;
  overrideColors?: Color[];
}

// -------------------
// Exported Components
// -------------------

export const Background: React.FC<IBackgroundProps> = ({
  height,
  overrideColors
}) => {
  const [colors, setColors] = useState(
    overrideColors ||
      ClientService.default.backgroundColors ||
      ConfigService.splashColors
  );
  const container = useRef<HTMLDivElement>(null);

  const chromaColors: chroma.Color[] = [];
  for (const color of colors) {
    chromaColors.push(chroma(color));
  }

  const [previousColors, setPreviousColors] = useState(chromaColors);

  useEffect(() => {
    setFavicon(previousColors[2]);
    ClientService.default.onBackgroundColorsChanged(setColors);
    return () => {
      ClientService.default.offBackgroundColorsChanged(setColors);
    };
    // eslint-disable-next-line
  }, [container, container.current]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (!areSameColors(previousColors, chromaColors)) {
      const blended = blend(previousColors, chromaColors);
      timeout = setTimeout(() => {
        setFavicon(blended[2]);
        setPreviousColors(blended);
      }, 100);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [previousColors, chromaColors]);

  document.body.style.backgroundColor = bodyColor;

  const width = window.innerWidth;
  height = height || window.innerHeight;

  return (
    <div
      className="background"
      style={{
        background: `linear-gradient(0deg, ${previousColors[2].hex()} 0%, ${previousColors[1].hex()} 50%, ${previousColors[0].hex()} 100%)`,
        height
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        version="1.1"
        pointerEvents="none"
      >
        <g transform={`scale(${width / 1300}, ${height / 800})`}>
          {/* Left */}

          <path
            d="M100,0L650,400L330,0L100,0Z"
            fill="white"
            fillOpacity={topOpacity}
          />

          <path
            d="M0,260L650,400L0,130L0,260Z"
            fill="white"
            fillOpacity={topOpacity}
          />

          <path
            d="M100,800L650,400L330,800L100,800Z"
            fill="white"
            fillOpacity={topOpacity}
          />

          <path
            d="M0,340L650,400L0,460L0,350Z"
            fill="white"
            fillOpacity={topOpacity}
          />

          <path
            d="M0,540L650,400L0,670L0,600Z"
            fill="white"
            fillOpacity={topOpacity}
          />

          {/* center */}
          <path
            d="M550,800L650,400L750,800L550,800Z"
            fill="white"
            fillOpacity={topOpacity}
          />
          <path
            d="M550,0L650,400L750,0L550,0Z"
            fill="white"
            fillOpacity={topOpacity}
          />

          {/* Right */}
          <path
            d="M1200,0L650,400L970,0L1200,0Z"
            fill="white"
            fillOpacity={topOpacity}
          />

          <path
            d="M1300,260L650,400L1300,130L1300,260Z"
            fill="white"
            fillOpacity={topOpacity}
          />

          <path
            d="M1300,340L650,400L1300,460L1300,350Z"
            fill="white"
            fillOpacity={topOpacity}
          />

          <path
            d="M1300,540L650,400L1300,670L1300,600Z"
            fill="white"
            fillOpacity={topOpacity}
          />

          <path
            d="M1200,800L650,400L970,800L1200,800Z"
            fill="white"
            fillOpacity={topOpacity}
          />
        </g>
      </svg>
    </div>
  );
};
