import { Exception } from "./Exception";
import { PuzzleState } from "./PuzzleState";
import { Random } from "./Random";
// ------------------
// Internal Constants
// ------------------
var segmentDelimiter = ":";
var moveDelimiter = ",";
// ----------------
// Exported Classes
// ----------------
var Puzzle = /** @class */ (function () {
    function Puzzle(initialState, moves, currentState) {
        this.priInitialState = initialState;
        this.priMoves = moves;
        this.priCurrentState = currentState;
    }
    Object.defineProperty(Puzzle.prototype, "rows", {
        get: function () {
            return this.priInitialState.rows;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Puzzle.prototype, "cols", {
        get: function () {
            return this.priInitialState.cols;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Puzzle.prototype, "length", {
        get: function () {
            return this.priInitialState.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Puzzle.prototype, "isSolved", {
        get: function () {
            return this.priCurrentState.isSolved;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Puzzle.prototype, "pastMoves", {
        get: function () {
            return this.priMoves.slice(0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Puzzle.prototype, "validMoves", {
        get: function () {
            return this.priCurrentState.validMoves;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Puzzle.prototype, "squares", {
        get: function () {
            return this.priCurrentState.squares;
        },
        enumerable: true,
        configurable: true
    });
    Puzzle.parse = function (stringified) {
        var puzzleStateStringified;
        var movesStringified;
        var segments = stringified ? stringified.split(segmentDelimiter) : [];
        if (segments.length <= 3) {
            puzzleStateStringified = stringified;
        }
        else if (segments.length === 4) {
            movesStringified = segments.pop();
            puzzleStateStringified = segments.join(segmentDelimiter);
        }
        var initialState;
        try {
            initialState = PuzzleState.parse(puzzleStateStringified || "");
        }
        catch (error) {
            throw new Exception(Puzzle.parseFailedErrorCode, error.message);
        }
        var moves = [];
        if (movesStringified) {
            var moveLabels = movesStringified.split(moveDelimiter);
            for (var i = 0; i < moveLabels.length; i++) {
                var move = parseInt(moveLabels[i], 10);
                if (isNaN(move) || move < 0 || move > initialState.length - 2) {
                    throw new Exception(Puzzle.parseFailedErrorCode, "The move at index '" + i + "' is out of range");
                }
                moves.push(move);
            }
        }
        var currentState;
        try {
            currentState = PuzzleState.fromMoves(initialState, moves);
        }
        catch (error) {
            throw new Exception(Puzzle.parseFailedErrorCode, error.message);
        }
        return new Puzzle(initialState, moves, currentState);
    };
    Puzzle.fromMoves = function (initialState, moves) {
        if (moves === void 0) { moves = []; }
        return new Puzzle(initialState, moves, PuzzleState.fromMoves(initialState, moves));
    };
    Puzzle.withSize = function (size) {
        return Puzzle.fromMoves(PuzzleState.withSize(size));
    };
    Puzzle.prototype.getSquare = function (row, col) {
        return this.priCurrentState.getSquare(row, col);
    };
    Puzzle.prototype.getSquareAtCell = function (cell) {
        return this.priCurrentState.getSquareAtCell(cell);
    };
    Puzzle.prototype.getRowAndColOfSquare = function (square) {
        return this.priCurrentState.getRowAndColOfSquare(square);
    };
    Puzzle.prototype.getCellOfSquare = function (square) {
        return this.priCurrentState.getCellOfSquare(square);
    };
    Puzzle.prototype.move = function (move) {
        var _a;
        var moves = typeof move === "number" ? [move] : move;
        this.priCurrentState = PuzzleState.fromMoves(this.priCurrentState, moves);
        (_a = this.priMoves).push.apply(_a, moves);
    };
    Puzzle.prototype.getRandomMove = function () {
        var validMoves = this.priCurrentState.validMoves;
        var lastMove = this.priMoves[this.priMoves.length - 1];
        if (lastMove !== undefined) {
            validMoves.splice(validMoves.indexOf(lastMove), 1);
        }
        return validMoves[Random.withMax(validMoves.length)];
    };
    Puzzle.prototype.tryMove = function (move) {
        try {
            this.move(move);
        }
        catch (error) {
            return false;
        }
        return true;
    };
    Puzzle.prototype.undo = function (count) {
        count = typeof count === "number" ? count : 1;
        if (count < 1 || count > this.priMoves.length) {
            throw new Exception(Puzzle.invalidUndoCountErrorCode, "The count argument is out of range");
        }
        var movesReversed = this.priMoves
            .splice(this.priMoves.length - count, count)
            .reverse();
        this.priCurrentState = PuzzleState.fromMoves(this.priCurrentState, movesReversed);
    };
    Puzzle.prototype.stringify = function () {
        return this.priMoves.length
            ? [
                this.priInitialState.stringify(),
                this.priMoves.join(moveDelimiter)
            ].join(segmentDelimiter)
            : this.priInitialState.stringify();
    };
    Puzzle.emptySquare = PuzzleState.emptySquare;
    Puzzle.invalidCellErrorCode = PuzzleState.invalidCellErrorCode;
    Puzzle.invalidRowErrorCode = PuzzleState.invalidRowErrorCode;
    Puzzle.invalidColErrorCode = PuzzleState.invalidColErrorCode;
    Puzzle.invalidSquareErrorCode = PuzzleState.invalidSquareErrorCode;
    Puzzle.invalidUndoCountErrorCode = "Puzzle/invalid-undo-count";
    Puzzle.parseFailedErrorCode = "Puzzle/parse-failed";
    return Puzzle;
}());
export { Puzzle };
