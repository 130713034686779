import { Color } from "shared";

// --------------
// Exported Class
// --------------

export class ConfigService {
  static get inTestMode(): boolean {
    return (
      process.env.NODE_ENV !== "production" &&
      process.env.REACT_APP_TEST_MODE === "true"
    );
  }

  static get local(): boolean {
    return process.env.NODE_ENV !== "production";
  }

  static get localPort(): number {
    const port = parseInt(process.env.REACT_APP_FUNCTIONS_PORT || "0", 10);
    return Number.isNaN(port) ? 0 : port;
  }

  static get localUrl(): string {
    return `http://localhost:${ConfigService.localPort}`;
  }

  static get apiKey(): string {
    return process.env.REACT_APP_FIREBASE_API_KEY || "<not set>";
  }

  static get appId(): string {
    return process.env.REACT_APP_FIREBASE_APP_ID || "<not set>";
  }

  static get authDomain(): string {
    return process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "<not set>";
  }

  static get projectId(): string {
    return process.env.REACT_APP_FIREBASE_PROJECT_ID || "<not set>";
  }

  static get sendMovesThrottle(): number {
    return 15 * 1000;
  }

  static get startTimeOffset(): number {
    return 5 * 1000;
  }

  static get restartIfExpiresOffset(): number {
    return 5 * 1000;
  }

  static get publicUrl(): string {
    return process.env.PUBLIC_URL;
  }

  static get puzzleUrl(): string {
    return ConfigService.publicUrl + "/puzzle";
  }

  static get splashImageUrl(): string {
    return ConfigService.puzzleUrl + "/black-white-dog-600.png";
  }

  static get splashColors(): Color[] {
    return [
      [154, 188, 179],
      [77, 140, 150],
      [14, 26, 74]
    ];
  }
}
