import * as Script from "./script";

// ------------------
// Internal Constants
// ------------------

const scope = "public_profile,email";

// ------------------
// Internal Variables
// ------------------

let fbapi: any = null;

// ------------------
// Internal Functions
// ------------------

async function getLoginAcessTokenOrNull(): Promise<string | null> {
  if (!fbapi) {
    await init();
  }
  return new Promise(resolve => {
    let accessToken: string | null = null;
    fbapi.getLoginStatus((response: any) => {
      if (response && response.authResponse) {
        accessToken = response.authResponse.accessToken || null;
      }

      resolve(accessToken);
    });
  });
}

// ------------------
// Exported Functions
// ------------------

export async function init(): Promise<void> {
  if (!fbapi) {
    fbapi = await Script.load("https://connect.facebook.net/en_US/sdk.js", {
      windowProperty: "FB"
    });
    fbapi.init({
      appId: process.env.REACT_APP_FACEBOOK_APP_ID,
      autoLogAppEvents: true,
      cookie: true,
      status: true,
      version: "v5.0",
      xfbml: true
    });
  }
}

export async function login(): Promise<string | null> {
  if (!fbapi) {
    await init();
  }

  let accessToken: string | null;
  accessToken = await getLoginAcessTokenOrNull();
  if (accessToken) {
    return accessToken;
  }

  return new Promise((resolve, reject) => {
    function onLogin(response: any) {
      if (response && response.authResponse) {
        accessToken = response.authResponse.accessToken || null;
      }

      resolve(accessToken);
    }

    fbapi.login(onLogin, { scope });
  });
}

export async function logout(): Promise<void> {
  if (!fbapi) {
    await init();
  }

  try {
    const accessToken = await getLoginAcessTokenOrNull();
    if (!accessToken) {
      return;
    }
  } catch (error) {
    return;
  }

  return new Promise((resolve, reject) => {
    function onLogout(response: any) {
      resolve();
    }

    try {
      fbapi.logout(onLogout);
    } catch (error) {
      // do nothing
    }
  });
}
