import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import * as serviceWorker from "./serviceWorker";

import App from "./comp/App";

// import App from "./preview/LogoPreview";
// import App from "./preview/LoginPreview";
// import App from "./preview/PuzzlePreview";
// import App from "./preview/PuzzleSizePreview";
// import App from "./preview/BackgroundPreview";
// import App from "./preview/PuzzleBackgroundPreview";
// import App from "./preview/LayoutPreview";
// import App from "./preview/SpinnerPreview";
// import App from "./preview/TimerPreview";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
