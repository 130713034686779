import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import React, { useLayoutEffect, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { useResize } from "../../hooks/";
import { ClientService, LocalStorageService } from "../../services";
import { Background } from "../Background";
import { GameScreen } from "../GameScreen";
import { LevelScreen } from "../LevelScreen";
import { LoaderScreen } from "../LoaderScreen";
import { SplashScreen } from "../SplashScreen";
import "./styles.css";

// ------------------
// Internal Constants
// ------------------

const showLoaderScreenKey = "show-loader-screen";

// --------------
// Internal Enums
// --------------

enum Screen {
  splash = "splash",
  loader = "loader",
  level = "level",
  game = "game"
}

// -------------------
// Exported Components
// -------------------

const App: React.FC = () => {
  const app = useRef<HTMLDivElement>(null);
  const appContent = useRef<HTMLDivElement>(null);
  const appHeightCheck = useRef<HTMLDivElement>(null);
  const contentSize = useResize(appContent);

  const [realHeight, setRealHeight] = useState(0);
  const [orientationChanges, setOrientationChanges] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [screen, setScreen] = useState(
    LocalStorageService.get(showLoaderScreenKey) === true
      ? Screen.loader
      : Screen.splash
  );

  useLayoutEffect(() => {
    function onWindowResize() {
      window.removeEventListener("resize", onWindowResize);
      if (appHeightCheck && appHeightCheck.current) {
        const rect = appHeightCheck.current.getBoundingClientRect();
        if (rect.height !== realHeight) {
          setRealHeight(rect.height);
        }
      }
    }

    function onOrientationChange() {
      if (orientationChanges < 1) {
        setOrientationChanges(orientationChanges + 1);
      } else {
        window.addEventListener("resize", onWindowResize);
      }
    }
    window.addEventListener("orientationchange", onOrientationChange);
    return () => {
      window.removeEventListener("orientationchange", onOrientationChange);
    };
    // eslint-disable-next-line
  }, [appHeightCheck, orientationChanges]);

  useLayoutEffect(() => {
    if (app && app.current) {
      if (screen === Screen.splash) {
        enableBodyScroll(app.current);
      } else {
        disableBodyScroll(app.current);
        window.addEventListener("scroll", () => window.scrollTo(0, 0));
      }
    }

    if (screen !== Screen.splash && !loaded) {
      ClientService.default.fetchGame();
      setLoaded(true);
    }

    if (screen !== Screen.splash) {
      LocalStorageService.set(showLoaderScreenKey, true);
    }
  }, [screen, loaded]);

  function nextScreen(fadeIn: boolean) {
    if (!fadeIn) {
      setScreen(screen === Screen.level ? Screen.game : Screen.level);
    }
  }

  function currentScreen() {
    switch (screen) {
      case Screen.loader:
        return <LoaderScreen fadeOut show onFadeChange={nextScreen} />;
      case Screen.splash:
        return (
          <SplashScreen
            fadeOut
            show
            onFadeChange={nextScreen}
            height={realHeight > contentSize.height ? realHeight : undefined}
          />
        );
      case Screen.level:
        return <LevelScreen fadeIn fadeOut show onFadeChange={nextScreen} />;
      case Screen.game:
        return <GameScreen fadeIn fadeOut show onFadeChange={nextScreen} />;
    }
  }

  const handlers = useSwipeable({
    onSwiped: () => {
      // do nothing
    },
    onSwiping: () => {
      // do nothing
    },
    preventDefaultTouchmoveEvent: true
  });

  return (
    <div
      className="app"
      ref={app}
      {...(screen !== Screen.splash ? handlers : [])}
    >
      <div className="app-height-check" ref={appHeightCheck} />
      <div className="app-background">
        <Background
          height={
            realHeight > contentSize.height ? realHeight : contentSize.height
          }
        />
      </div>
      <div className="app-content" ref={appContent}>
        {currentScreen()}
      </div>
    </div>
  );
};

export default App;
